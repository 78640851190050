<div class="footer">
  <div class="footerContainer">
    <a class="picture flex" id="facebookLogo" [href]="'https://www.facebook.com/Decitto-Entscheidet-gemeinsam-104027998473655'" target="_blank" title="Folge uns auf Facebook" alt="Folge uns auf Facebook">
      <img src="../../../assets/logos/Facebook_f_logo_(2019).svg" height="17px" alt="Folge uns auf Facebook">
    </a>
    <a class="picture flex" id="twitterLogo" [href]="'https://twitter.com/decitto'" target="_blank" title="Folge uns auf Twitter" alt="Folge uns auf Twitter">
        <img src="../../../assets/logos/twitter.svg" height="18px" alt="Folge uns auf Twitter">
    </a>
    <div class="text flex" (click)="onAboutClicked()" >Über uns</div>
    <div class="text flex" (click)="onHelpClicked()" >Hilfe</div>
    <div class="text flex" (click)="onFAQClicked()" >FAQ</div>
    <div class="text flex" (click)="onKontaktClicked()">Kontakt</div>
    <div class="text flex" (click)="onImpressumClicked()" >Impressum & Datenschutz</div>
  </div>
</div>