<div class="container" [class.containerMobile]="mobileViewInformation.mobileView">
    <div class="box ranking" *ngIf="!mobileViewInformation.mobileView"></div>
    <div class="box" [ngClass]="{'text': !mobileViewInformation.mobileView , 'textMobile' : mobileViewInformation.mobileView }">
        <app-textfield
            [placeholder]="'neuer Vorschlag'"
            [(content)]="addLabel"
            [fontsize]="'19px'"
            (enter)="onEnter($event)"
        ></app-textfield>
    </div> 
    <img class="addButton" height="60px" title="Vorschlag hinzufügen" src="../../../assets/buttons/+.svg" (click)="onAddButton()">
</div>
