<div class="fullScreenPage" id="faq-page">
    <div class="close" (click)="onClickClose()">
        <img src="../../../assets/buttons/close.svg" width="30px" class="closePic">
    </div>
    <div class="fullScreenContent">
        <H1>Häufige Fragen (FAQ)</H1>
        <div class="tabs">
            <div class="tab">
              <input type="checkbox" id="chck1" class="invisible">
              <label class="tab-label" for="chck1">Kann ich DecItTo! ganz ohne Anmeldung nutzen?</label>
              <div class="tab-content">
                Ja, DecItTo! ist ganz ohne Anmeldung nutzbar. Für die Verifizierung wird der Link mit der darin enthaltenen ID verwendet. 
                Der Link kann so an die anderen Mitentscheider weitergegeben werden und diese können direkt auf die gleiche Session zugreifen. 
                Jede Person in Besitz des Links kann auf die Daten zugreifen. Da keine gesonderte Passwortabfrage erfolgt, speichere bitte keine sensiblen Informationen
                in der DecItTo! Session.
              </div>
            </div>
            <div class="tab">
              <input type="checkbox" id="chck2" class="invisible">
              <label class="tab-label" for="chck2">Ist DecItTo! vollständig kostenfrei nutzbar?</label>
              <div class="tab-content">
                Ja, DecItTo! ist vollständig kostenfrei nutzbar. DecItTo! ist neben meinem Informatik Studium in der Freizeit entwickelt worden und ist entstanden, um Entscheidungen einfacher gemeinsam zu treffen. 
                Um eine kleine Entschädigung dafür zu erhalten, schalten wir auf der Webseite Werbung. Daher gerne den AdBlocker ausschalten, damit wir kleine Einnahmen durch die Werbung erzielen können. 
                Über eine kleine direkte Unterstützung sind wir natürlich auch sehr dankbar und freuen uns sehr. Hierzu ist ein PayPal Button unten auf der Seite vorhanden. Vielen Dank!
            </div>
            </div>
            <div class="tab">
                <input type="checkbox" id="chck3" class="invisible">
                <label class="tab-label" for="chck3">Wer steckt hinter DecItTo!?</label>
                <div class="tab-content">
                  DecItTo! ist durch mich während des Corona Lockdowns in der Freizeit neben meinem Masterstudium in Wirtschaftsinformatik entwickelt worden. Im Impressum findest du die notwendigen Informationen über mich. 
                  Ein paar lange Nächte stecken zwar in der Entwicklung, aber die Lernkurve war dafür umso steiler. ;-) Dafür ist DecItTo! vollständig kostenfrei nutzbar. Über eine kleine Unterstützung über den unten auf der Seite verlinkten PayPal Button freue ich mich natürlich trotzdem sehr. 
                </div>
              </div>
              <div class="tab">
                <input type="checkbox" id="chck4" class="invisible">
                <label class="tab-label" for="chck4">Welche Informationen muss ich bei DecItTo! zur Nutzung hinterlegen?</label>
                <div class="tab-content">
                  Es sind nur die Informationen notwendig, die du für die jeweilige Entscheidung hinterlegen möchtest. Es gibt keinerlei Pflichtfelder. Bei den Namensfelder können echte Namen oder beliebige andere Namen 
                  eingetragen werden. Es findet auch keine Prüfung statt, ob du ein Roboter bist. ;-) 
              </div>
              </div>
              <div class="tab">
                <input type="checkbox" id="chck5" class="invisible">
                <label class="tab-label" for="chck5">Wo werden die Daten bei DecItTo! gespeichert!</label>
                <div class="tab-content">
                  DecItTo! wird von Google Inc. („Google“) in den USA gehostet. Daher werden auch die Daten in einer Datenbank gespeichert, die auf Google Servern in den USA liegt. Bitte beachte dies, wenn du
                  Bedenken zum Datenschutz bezüglich deiner Daten hast, die du bei DecItTo! speichern willst. Logischerweise werden daher auch alle gespeicherten Informationen in die USA übermittelt. 
                  Die Übermittlung erfolgt über eine mit SSL verschlüsselte Verbindung. 
                  Grundsätzlich ist keinerlei Speicherung von Klarnamen oder sonstigen personenbezogenen Daten notwendig, um DecItTo! zu nutzen. 
                  Den Link kannst du über die vorhandenen Social Media Apps teilen. Natürlich funktionieren jedoch auch alle anderen Wege, wie man einen Link teilen kann, egal ob Mail oder Brief.
                </div>
              </div>
              <div class="tab">
                <input type="checkbox" id="chck6" class="invisible">
                <label class="tab-label" for="chck6">Muss ich älter als 18 Jahre sein, um DecItTo! nutzen zu können?</label>
                <div class="tab-content">
                    Nein, die Nutzung von DecItTo! unterliegt keiner Altersbeschränkung. Lediglich eine kleine Auswahl von Bewertungssysmbolen ist erst ab 18 Jahren freigegeben. Um diese erweiterte Auswahl 
                    an Bewertungssymbolen nutzen zu können müssen alle Mitentscheider über 18 Jahren sein. Daher erfolgt die Abfrage beim Start der neuen Session. Ist es nicht gewollt die erweiterte Auswahl zu nutzen, 
                    ist auch die Bestätigung der Volljährigkeit nicht notwendig. Die Volljährigkeit lässt sich nur beim Anlegen der Session bestätigen. Eine nachträgliche Bestätigung ist nicht mehr möglich. 
              </div>
              </div>
              <div class="tab">
                <input type="checkbox" id="chck7" class="invisible">
                <label class="tab-label" for="chck7">Was verbirgt sich hinter den Bewertungssymbolen, die erst ab 18 Jahren freigegeben sind?</label>
                <div class="tab-content">
                  Um ein bisschen Spaß in der Gruppe bei der Nutzung von DecItTo! zu bekommen, gibt es zwei Bewertungssymbole, die erst ab 18 Jahren freigegeben sind. Hierbei handelt es sich um zwei Symbole, 
                  die zum einen die Freude bei einem Mann und einmal die Freude bei einer Frau mit Symbolen ausdrücken. Um ungewolltes Ändern der Bewertungssymbole während der Nutzung von DecItTo! zu verhindern, lässt sich
                  die Bestätigung der Volljährigkeit nur beim Anlegen der Session durchführen. Eine nachträgliche Aktivierung der Symbole ist nicht mehr möglich. 
                </div>
              </div>
              <div class="tab">
                <input type="checkbox" id="chck8" class="invisible">
                <label class="tab-label" for="chck8">Lässt sich eine Bewertung wieder auf 0 Sterne zurücksetzen?</label>
                <div class="tab-content">
                  Nein, eine Bewertung lässt sich nicht mehr auf 0 Sterne zurücksetzen. Hierdurch lässt sich unterscheiden, ob eine Person bereits abgestimmt hat oder noch nicht. Eine schlechte Bewertung entspricht 
                  1 Stern. 0 Sterne entsprechen einer noch nicht abgegebenen Bewertung. In den Einstellungen lässt sich ändern, ob 0 Sterne Bewertungen in die Gesamtbewertung einfließen sollen oder nicht. Standardmäßig ist dies 
                  aktiviert, da sonst die ersten Personen anfänglich einen hohen Einflus auf die Gesamtbewertung der Vorschläge hätten. Diese Einstellung lässt sich jedoch jederzeit im Einstellungsmenü anpassen. 
              </div>
                </div>
                <div class="tab">
                <input type="checkbox" id="chck15" class="invisible">
                <label class="tab-label" for="chck15">Warum werden 0 Sterne Bewertungen in die Gesamtbewertung eingerechnet?</label>
                <div class="tab-content">
                    Standardmäßig ist diese Einstellung aktiviert, da sonst die ersten Personen anfänglich einen hohen Einflus auf die Gesamtbewertung der Vorschläge hätten. Diese Einstellung lässt sich jedoch jederzeit im Einstellungsmenü anpassen.
                    Dann werden nur noch abgegebene Bewertungen mit einem Stern oder mehr in die Gesamtbewertung eingerechnet.
                </div>
              </div>
                <div class="tab">
                <input type="checkbox" id="chck9" class="invisible">
                <label class="tab-label" for="chck9">Kann ich einen nicht gespeicherten Link / verloren gegangenen Link wieder generieren?</label>
                <div class="tab-content">
                  Nein, leider nicht. Der Link wird nur einmalig generiert. Wird er nicht gespeichert oder an Mitentscheider weitergeleitet gibt es leider keine Möglichkeit mehr den Link erneut zu 
                  generieren. Der Link könnte jedoch im Browserverlauf gespeichert sein. Daher hilft es in dem Verlauf des Browsers einmal nachzuschauen, ob der Link dort noch vorhanden ist. 
                  Ist dies nicht der Fall hilft leider nur eine neue Session zu starten und den neuen Link zu speichern und zu nutzen. Aus Datenschutzgründen werden keine personenbezogenen Daten abgefragt. 
                  Daher ist es auch nicht möglich eine E-Mail mit dem Link zu versenden. 
                </div>
              </div>
              <div class="tab">
                <input type="checkbox" id="chck10" class="invisible">
                <label class="tab-label" for="chck10">Kann ich einen gelöschten Vorschlag oder gelöschten Mitentscheider wiederherstellen?</label>
                <div class="tab-content">
                  Nein, die Daten werden beim Löschen unwiederruflich und sofort gelöscht. Daher ist keine Wiederherstellung möglich. 
              </div>
              </div>
              <div class="tab">
                  <input type="checkbox" id="chck11" class="invisible">
                  <label class="tab-label" for="chck11">Ist es möglich die Bewertungssymbole (Ü18) im Nachhinein zu verändern?</label>
                  <div class="tab-content">
                    Die Bewertungssymbole können über das Einstellungsmenü auch nachträglich angepasst werden. Sollte bereits zu Beginn der Session die Volljährigkeit bestätigt worden sein, steht hierfür eine erweiterte
                    Auswahl an Symbolen zur Verfügung. Nachträglich kann jedoch aus Sicherheitsgründen die Volljährigkeit nicht mehr bestätigt werden. Daher steht die erweiterte Auswahl im Nachhinein nicht zur Verfügung.
                  </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck12" class="invisible">
                  <label class="tab-label" for="chck12">Wie kann ich die mobile Ansicht aktivieren?</label>
                  <div class="tab-content">
                    Die mobile Ansicht wird automatisch auf Smartphones im Portraitmodus (hochkant) aktiviert. Im Einstellungsmenü lässt sich die mobile Ansicht bis zum Neuladen der Seite ausschalten. 
                    Auf Smartphones im horizontalen Modus ist die gewohnte Standardansicht aktiv. 
                </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck13" class="invisible">
                  <label class="tab-label" for="chck13">Wie kann ich den Mitentscheider in der mobilen Ansicht ändern?</label>
                  <div class="tab-content">
                    In der mobilen Ansicht lässt sich der Nutzer mit einem Klick auf den Namen des Mitentscheiders auswählen.
                  </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck14" class="invisible">
                  <label class="tab-label" for="chck14">Warum kann ich Vorschläge mit "Ausgewählt!" oder "Nicht möglich!" nicht neu bewerten?</label>
                  <div class="tab-content">
                    Vorschläge mit "Ausgewählt!" oder "Nicht möglich!" stehen nicht dem normalen Entscheidungsprozess zur Verfügung, daher ist eine Bewertung für diese Vorschläge nicht mehr möglich. 
                    Solltest du trotzdem die Vorschläge neu bewerten möchten, deaktiviere "Ausgewählt!" oder "Nicht möglich!", bewerte den Vorschlag und aktiviere "Ausgewählt!" oder "Nicht möglich!" erneut. 
                </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck17" class="invisible">
                  <label class="tab-label" for="chck17">Muss ich den aktuellen Stand speichern?</label>
                  <div class="tab-content">
                    Nein, jede Änderung wird sofort gespeichert und in Echtzeit bei den Mitentscheidern angezeigt. Auch ein Neuladen der Seite verliert keine Informationen, da die Daten sofort in der Datenbank
                    abgespeichert werden. 
                </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck18" class="invisible">
                  <label class="tab-label" for="chck18">Wie lange kann ich eine Session nutzen? / Wann werden die Daten gelöscht?</label>
                  <div class="tab-content">
                    Eine Session ist 6 Monate lang nutzbar. Anschließend werden die Daten automatisch und unwiederruflich gelöscht. Leider lässt sich dieser Zeitraum nicht verlängern. Im Normalfall reichen 
                    die 6 Monate zur Nutzung einer Session aus. Sollte ausnahmsweise eine Session doch länger als 6 Monate genutzt werden sollen, kontaktiere uns über den untenstehenden Kontakt Button und 
                    der Zeitraum kann um weitere 6 Monate verlängert werden. 
                </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck16" class="invisible">
                  <label class="tab-label" for="chck16">Bei mir funktioniert das nicht! / Ich habe einen Fehler gefunden!</label>
                  <div class="tab-content">
                    Wir sind dauernd an der Verbesserung dieser Seite interessiert. Kontaktiere uns gerne unten über die Kontakt-Schaltfläche, wenn du einen Fehler gefunden hast oder DecItTo! bei dir nicht funktioniert. 
                    Über Feedback freuen wir uns immer sehr. 
                </div>
                  </div>
            </div>
    </div>
</div>  