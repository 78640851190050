<div class="container" 
    [class.won]="suggestion.wonStatus" 
    [class.transitionUp]="suggestion.positionChanged == 1" 
    [class.transitionDown]="suggestion.positionChanged == 2" 
    [class.impossible]="suggestion.impossibleStatus"
    [class.containerMobile]="mobileViewInformation.mobileView">
    <div [ngClass]="{'ranking': !mobileViewInformation.mobileView , 'box': !mobileViewInformation.mobileView , 'rankingMobile' : mobileViewInformation.mobileView }">
        <app-ranking
            [rank]="suggestion.position"
            [suggestionId]="suggestion.id"
            [rating]="suggestion.overallrate"
            [symbolNumber]="suggestion.symbolNumber"
            [wonStatus]="suggestion.wonStatus"
            [sugLabel]="suggestion.label"
            [mobileView]="mobileViewInformation.mobileView"
            (wonStatusChange)="onWonStatus($event)"
            [impossibleStatus]="suggestion.impossibleStatus"
            (impossibleStatusChange)="onImpossibleStatus($event)"
            (delete)="onDelete()"
        ></app-ranking>
    </div>
    <div class="box" [ngClass]="{'text': !mobileViewInformation.mobileView , 'textMobile' : mobileViewInformation.mobileView }">
        <app-textfield
            [placeholder]="'Vorschlag'"
            [label]="'Vorschlag'"
            [id]="'inputLabel_sug_'+suggestion.id"
            [content]="suggestion.label"
            (change)="onLabelChange($event)"
            (enter)="onLabelChange($event)"
            [fontsize]="'19px'"
        ></app-textfield>
        <div class="symbolsArea">
            <div 
            class="symbol linkSymbols" 
            [class.linkSymbolsMobile]="mobileViewInformation.mobileView">
                <div
                class="pointer" 
                style="padding-right: 5px;"
                (click)="onLinkClicked()">
                    <img src="../../../assets/buttons/external-link-symbol.svg" width="14" style="padding-top: 1px;">
                </div>
                <div 
                [innerHTML]="linkText"
                class="pointer"
                [class.symbolColor]="suggestionLink != ''"
                (click)="onLinkClicked()"></div>
                <div *ngIf="suggestion.link != ''" 
                class="pointer"
                style="padding-left: 10px"
                (click)="showAddLinkBox=true">
                    <img title="Link bearbeiten" src="../../../assets/buttons/bleistift.svg" width="12" style="padding-top: 2px;" (click)="showAddLinkBox">
                </div>
            </div>
            <div 
            class="symbol" 
            id="symbolDescription" >
                <div (click)="showAddDescriptionBox=true" class="pointer">
                    <img src="../../../assets/buttons/copy.svg" width="18" style="padding-right: 5px; padding-top: 1px;">
                </div>
                <div 
                [innerHTML]="descriptionText"
                id="symbolDescriptionText"
                class="symbol pointer"
                [class.symbolColor]="suggestion.description != ''" 
                (click)="showAddDescriptionBox=true"></div>
            </div>
        </div>        
    </div>
    <div class="boxContainer" *ngIf="!mobileViewInformation.mobileView">
        <div *ngFor="let id of userIds" class="box rating">
            <app-user-rating 
                [userSugId]="userSugId[id]" 
                [rating]="suggestion.userRatings[id]" 
                (ratingChange)="onRatingChange($event, id)" 
                [userComment]="suggestion.userComments[id]" 
                (userCommentChange)="onUserComment($event, id)"
                [inputUsable]="inputUsable"
                [symbolNumber]="suggestion.symbolNumber">
            </app-user-rating>
        </div>
    </div>
    <div class="boxContainer" *ngIf="mobileViewInformation.mobileView">
        <ng-container *ngFor="let id of userIds">
            <app-user-rating 
                class="box ratingMobile"
                *ngIf="mobileViewInformation.mobileUserShown === id"
                [userSugId]="userSugId[id]" 
                [rating]="suggestion.userRatings[id]" 
                (ratingChange)="onRatingChange($event, id)" 
                [userComment]="suggestion.userComments[id]" 
                (userCommentChange)="onUserComment($event, id)"
                [inputUsable]="inputUsable"
                [symbolNumber]="suggestion.symbolNumber">
            </app-user-rating>
        </ng-container>
    </div>
</div>

<!--Communication Box for add link to suggestion-->
<app-communication-box
    *ngIf="showAddLinkBox"
    [id]="'addLink'"
    [placeholder]="'Link'"
    [question]="'Link für &ldquo;'+suggestion.label.substr(0,20)+'&rdquo;:'"
    (close)="showAddLinkBox=$event"
    (contentChange)="onAddLink($event)"
    [initialContent]="suggestion.link">
</app-communication-box>

<!--Communication Box for add description to suggestion-->
<app-communication-box
    *ngIf="showAddDescriptionBox"
    [id]="'addDescription'"
    [placeholder]="'Beschreibung'"
    [question]="'Beschreibung für &ldquo;' + suggestion.label.substr(0,20) + '&rdquo;:'"
    (close)="showAddDescriptionBox=$event"
    (contentChange)="onAddDescription($event)"
    [initialContent]="suggestion.description"
    [textarea]="true">
</app-communication-box>