

   <!-- Links -->
   <div class="card-container">
    <a class="circle-link" title="Über E-Mail teilen" [href]="mailString" target="_top" rel="noopener">
      <img id="mail" src="../../../assets/logos/email.svg" alt="Über E-Mail teilen" width="23">
      <svg xmlns="http://www.w3.org/2000/svg" width="19.447" height="19.447" viewBox="0 0 19.447 19.447">
      </svg>
    </a>

    <a class="circle-link" title="Über WhatsApp teilen" [href]="whatsappString" target="_blank" rel="noopener">
      <img id="whatsapp" src="../../../assets/logos/WhatsApp.svg" alt="Über WhatsApp teilen" width="25">
      <svg id="Group_20" data-name="Group 20" xmlns="http://www.w3.org/2000/svg" width="21.813" height="23.453" viewBox="0 0 21.813 23.453">
      </svg>
    </a>

    <a class="circle-link" title="Über Facebook Messenger teilen" [href]="facebookString" target="_blank" rel="noopener">
      <img id="facebook" src="../../../assets/logos/Facebook_Messenger.svg" alt="Über Facebook Messenger teilen" width="26">
      <svg xmlns="http://www.w3.org/2000/svg" width="21.762" height="23.447" viewBox="0 0 21.762 23.447">
      </svg>
    </a>

    <a class="circle-link" title="Über Telegram teilen" [href]="telegramString" target="_blank" rel="noopener">
      <img id="telegram" src="../../../assets/logos/Telegram-Logo.svg" alt="Über Telegram teilen" width="58">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21.81" height="23.447" viewBox="0 0 21.81 23.447">
       </svg>
    </a>

    <a class="circle-link" title="Über Threema! teilen" [href]="threemaString" target="_blank" rel="noopener">
      <img id="threema" src="../../../assets/logos/threema.svg" alt="Über Threema teilen" width="23">
      <svg xmlns="http://www.w3.org/2000/svg" width="21.81" height="23.447" viewBox="0 0 21.81 23.447">
      </svg>
    </a>

    <div class="circle-link" title="Link kopieren" (click)="onCopyClick()" rel="noopener">
      <img *ngIf="showCopyLink" id="copy" src="../../../assets/logos/link.svg" alt="Link kopieren" width="22">
      <img *ngIf="showCopySuccess" id="copySuccess" src="../../../assets/logos/success.svg" width="30">
      <svg xmlns="http://www.w3.org/2000/svg" width="24.607" height="23.447" viewBox="0 0 24.607 23.447">
      </svg>
    </div>

  </div>

  <div class="developer-container fontWeightLight">
    Developed with &#10084; in Eißendorf, Hamburg
  </div>
  <div class="paypal-container fontWeightLight" id="paypal-text">
    Gefällt dir diese Webseite? Über eine kleine Spende freuen wir uns sehr:   
    <a class="paypal" id="paypal-button" href="https://paypal.me/achimroell?locale.x=de_DE" target="_blank">
    <img src="../../../assets/buttons/paypal-donate-button.png" width="90px" title="Spenden über PayPal" alt="Spenden über PayPal"/>
    </a>
  </div>
  <div class="further-projects fontWeightLight">
    Schaue dir auch unsere weiteren Projekte an:&nbsp; <a class="linkOther" id="spieleraetselLink" href="http://spieleraetsel.de" target="_blank">Rätselspaß bei kostenfreien Exit-Games unter spieleraetsel.de</a>
  </div>