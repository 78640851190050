import { Component, OnInit, Input, EventEmitter, Output, HostBinding } from '@angular/core';
import { Suggestion } from '../../_interface/suggestion';
import { MobileViewInformation } from '../../_interface/mobile-view-information';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss'],
})
export class SuggestionComponent implements OnInit {

  @Input()
  public suggestion: Suggestion;

  @Input()
  public mobileViewInformation: MobileViewInformation;

  @Output()
  public suggestionChange = new EventEmitter<Suggestion>();

  @Output()
  public delete = new EventEmitter<Suggestion>();
  
  public userIds: number[] = [];
  public userSugId: string[] = [""];
  public suggestionWidth ="";

  public suggestionLink="";
  public linkText="Link hinzufügen";
  public descriptionText="Beschreibung hinzufügen";
  public showAddLinkBox = false;
  public showAddDescriptionBox = false;
  public inputUsable = true;
  private currentMobileView = false;

  @HostBinding("attr.style")
  public get getSuggestionWidth(): any {
    this.calculateWidth();
    return this.sanitizer.bypassSecurityTrustStyle(`--suggestionWidth: ${this.suggestionWidth}; `);
  }

  constructor(private sanitizer: DomSanitizer) {
    this.mobileViewInformation ={
      mobileView: false,
      mobileUserShown: 0,
      screenHeight: 0,
      screenWidth: 0,
    };
    this.suggestion = {
      id: 0,
      label: "",
      overallrate: 0,
      wonStatus: false,
      nbuser: 0,
      position: 0,
      userRatings: [],
      trackID:0,
      positionChanged: 0,
      userComments: [],
      description: "",
      link: "",
      impossibleStatus: false,
      symbolNumber: 0,
    }   
  }

  ngOnInit(): void {
    for (let i = 0; i<this.suggestion.nbuser; i++){
      this.userSugId[i]= "user_" + (i) + "_sug_" + this.suggestion.id;
      this.userIds[i]=i;
    }
    this.calculateWidth();
    this.updateText(this.mobileViewInformation.mobileView);
    if(this.suggestion.wonStatus || this.suggestion.impossibleStatus){
      this.inputUsable = false;
    }
  }

  ngOnChanges(): void {
  }

  ngDoCheck(): void {
    if(this.mobileViewInformation.mobileView !== this.currentMobileView){
      this.updateText(this.mobileViewInformation.mobileView);
      this.currentMobileView = this.mobileViewInformation.mobileView;
    } 
  }
 
  onRatingChange(event: any, userid: number): void {
    this.suggestion.userRatings[userid] = event;
    this.suggestionChange.emit(this.suggestion);
  }

  onUserComment(event: any, userid: number): void {
    this.suggestion.userComments[userid] = event as string;
    this.suggestionChange.emit(this.suggestion);
  }

  onLabelChange(event: any): void {
    this.suggestion.label = event as string;
    this.suggestionChange.emit(this.suggestion);
  }

  onAddLink(event: any): void {
    this.suggestion.link = event as string;
    this.showAddLinkBox = false;
    this.suggestionChange.emit(this.suggestion);
  }

  onAddDescription(event: any): void {
    this.suggestion.description = event as string;
    this.showAddDescriptionBox = false;
    this.suggestionChange.emit(this.suggestion);
  }

  onLinkClicked():void {
    if(this.suggestion.link == ""){
      this.showAddLinkBox=true;
    }
    else if(this.suggestion.link.substr(0,7) == "http://"){
      window.open(this.suggestion.link,'_blank');
    }
    else if(this.suggestion.link.substr(0,8) == "https://"){
      window.open(this.suggestion.link,'_blank');
    }
    else{
      window.open("https://" + this.suggestion.link,'_blank');
    }
  }

  onDelete():void{
    this.delete.emit(this.suggestion);
  }

  onWonStatus(event: boolean): void {
    this.suggestion.wonStatus=event;
    if (event){
      this.suggestion.impossibleStatus=false;
    }
    this.suggestionChange.emit(this.suggestion);
  }

  onImpossibleStatus(event: boolean): void {
    this.suggestion.impossibleStatus=event; 
    if (event){
      this.suggestion.wonStatus=false;
    }
    this.suggestionChange.emit(this.suggestion);
  }

  private calculateWidth(): void{
    if (!this.mobileViewInformation.mobileView){
      this.suggestionWidth = (50 + 13.5*this.suggestion.nbuser) + "rem";
    }
    else if (this.mobileViewInformation.mobileView){
      this.suggestionWidth = "28rem";
    }
  }

  private updateText(mobileView: boolean): void{
    this.suggestionLink = this.suggestion.link;
    if(this.suggestion.link != ""){
      this.linkText="Link öffnen";
    }
    else{
      this.linkText="Link hinzufügen";
    }
    if(this.suggestion.description != ""){
      this.descriptionText="Beschreibung ansehen";
    }
    else{
      this.descriptionText="Beschreibung hinzufügen";
    }
    if(this.mobileViewInformation.mobileView){
      this.linkText="Link";
      this.descriptionText="Beschreibung";
    }
  }
}
