import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {

  @Input()
  public rank = 1;

  @Input()
  public rating = 1;

  @Input()
  public suggestionId = 1;

  @Input()
  public symbolNumber = 0;

  @Input()
  public wonStatus = false;

  @Input()
  public impossibleStatus = false;

  @Input()
  public sugLabel = "";

  @Input()
  public mobileView = false;

  @Output()
  public wonStatusChange = new EventEmitter<boolean>();

  @Output()
  public impossibleStatusChange = new EventEmitter<boolean>();

  @Output()
  public delete = new EventEmitter<boolean>();

  public rankTitel = ""; 
  public rateId = "";
  public roundedRating = 1;
  public showSugDelConfirmBox = false;

  constructor() { 
  }

  ngOnInit(): void {
    if (this.wonStatus){
    this.rankTitel = 'Ausgewählt!'
    }
    else if (this.impossibleStatus) {
    this.rankTitel = 'Nicht möglich!'
    }
    else {
    this.rankTitel = 'Platz: ' + this.rank;
    }
    this.rateId = 'sug_' + this.suggestionId + '_raking';
    this.roundedRating = Math.floor(this.rating);
  }

  onWonStatus(event?: any):void{
    this.wonStatus = !this.wonStatus;
    this.wonStatusChange.emit(this.wonStatus);
  }

  onSetImpossible(event?: any):void{
    this.impossibleStatus = !this.impossibleStatus;
    this.impossibleStatusChange.emit(this.impossibleStatus);
  }

  onDelete(event?: any):void{
    this.delete.emit();
  }
}

