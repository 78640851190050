<div class="main">
  <div id="background_wrap"></div>
  <app-template-header *ngIf="headerShown"></app-template-header>
  <div class="feature">
    <app-feature-container
      *ngIf="mainFeatureAvailable"
      [newSessionInformation]="newSessionInformation"
      [sessionString]="sessionString"
    ></app-feature-container>
  </div>
  <app-start-page
    *ngIf="startpageShown"
    (newSessionStart)="onStartpageStatus($event)"
  ></app-start-page>
  <app-impressum 
    *ngIf="impressumShown"
    (impressumClosed)="onImpressumStatus($event)"
  ></app-impressum>
  <app-faq
    *ngIf="faqShown"
    (faqClosed)="onFaqStatus($event)"
  ></app-faq>
  <app-about
    *ngIf="aboutShown"
    (aboutClosed)="onAboutStatus($event)"
></app-about>
  <app-help-page
    *ngIf="helpShown"
    (helpClosed)="onHelpStatus($event)"
    (toFaqChange)="onFaqChange($event)"
  ></app-help-page>
  <app-template-footer 
    *ngIf="footerShown && !startpageShown"
    (impressumShow)="onImpressumStatus($event)" 
    (faqShow)="onFaqStatus($event)"
    (helpShow)="onHelpStatus($event)"
    (aboutShow)="onAboutStatus($event)">
  </app-template-footer>
</div>
