<div [class]="className">
    <input type="radio" [name]="rateId" [id]="rateIdNumbers[4]" value="5" [checked]="rating === 5">
    <label [for]="rateIdNumbers[4]" (click)="onChangeRating(5)"></label>
    <input type="radio" [name]="rateId" [id]="rateIdNumbers[3]" value="4" [checked]="rating === 4">
    <label [for]="rateIdNumbers[3]" (click)="onChangeRating(4)"></label>
    <input type="radio" [name]="rateId" [id]="rateIdNumbers[2]" value="3" [checked]="rating === 3">
    <label [for]="rateIdNumbers[2]" (click)="onChangeRating(3)"></label>
    <input type="radio" [name]="rateId" [id]="rateIdNumbers[1]" value="2" [checked]="rating === 2">
    <label [for]="rateIdNumbers[1]" (click)="onChangeRating(2)"></label>
    <input type="radio" [name]="rateId" [id]="rateIdNumbers[0]" value="1" [checked]="rating === 1">
    <label [for]="rateIdNumbers[0]" (click)="onChangeRating(1)"></label>
</div>