<div class="fullScreenPage" id="about-page">
    <div class="close" (click)="onClickClose()">
        <img src="../../../assets/buttons/close.svg" width="30px" alt="Schließen" class="closePic">
    </div>
    <div class="fullScreenContent">
        <H1>Über uns</H1>
        <p>
            Sich mit mehreren Personen auf eine Sache zu einigen ist oft gar nicht so leicht. Als erstes muss man den Überblick behalten welche verschiedenen Möglichkeiten sich bieten.<br> 
            Jede Person hat auch noch verschiedene Präferenzen, zwischenzeitlich fallen einige Optionen weg und Personen ändern ihre Meinung. Damit ist das Chaos dann perfekt. 
        </p>
        <p>
            Ende Februar 2021 hatten wir, wie jedermann, langsam genug von dem ganzen Lockdown und entschieden uns allmählich Gedanken über den Sommerurlaub zu machen. <br> 
            Ein Ferienhaus an der Ostsee sollte es sein. <br> 
            Doch das war gar nicht so leicht zu finden. Viele waren schneller als wir und die Suche nach einer geeigneten Ferienunterkunft in Deutschland - die auch noch möglichst Corona sicher sein sollte - stellte sich
            als gar nicht so leicht heraus. <br> 
            Damit standen wir vor dem Problem zwischen unterschiedlichen, sich je nach Buchungssituation permanent ändernder Optionen, gemeinsam entscheiden zu müssen.<br>
            Um den Überblick zu behalten erstellten wir eine Excel Liste, auf die jeder Zugriff hatte und seine Vorschläge eintragen konnte. <br> 
            Doch das war auch nicht das Wahre und der Überblick über die verschiedenen Optionen ging schnell verloren.
        </p>
        <p>
            Wir sind Rainer und Achim und haben uns gedacht, dass es doch eine einfachere Möglichkeit geben müsste als immer Excel Listen zu erstellen. <br> 
            Doch wir fanden keine geeignete Möglichkeit.
        </p>
        <p>
            Ich, Achim, war gerade fertig mit meiner letzten Informatik Klausur im Masterstudium und dachte mir, wenn es sowas nicht gibt, dann muss man eben ein Tool dafür schaffen. <br>
            So ist die Idee zu DecItTo! geboren und ich machte mich ans programmieren. <br>
            Einige Wochen mit langen Nächten vergingen und DecItTo! nahm langsam Form an. <br>
        </p>
        <img src="../../../assets/pictures/Logo mit Text.svg" width="400em" class="pic" id="logoAbout" alt="Logo mit Beschreibung">
        <p>
            So war es nun möglich verschiedene Vorschläge in Listenform anzeigen zu lassen und jeder konnte die Vorschläge einzeln bewerten. <br>
            Der am besten bewerteste Vorschlag wird direkt oben angezeigt und man kann sich gemeinsam für den besten Vorschlag entscheiden. <br>
            Optionen, die nicht mehr möglich waren, verschwanden unten sowie Links und Beschreibungen zu den Ferienhäusern waren direkt unter dem Namen anklickbar. <br>
            Nach und nach kam die Idee die klassischen Bewertungssymbole doch mit ein paar anderen Symbolen abwechlungsreicher zu gestalten und verschiedene Icons wurden eingefügt. <br>
            Eines Abends kam uns die Idee doch ein paar Symbole für Ü18 einzufügen und der Spaß die eigene Plattform zu nutzen stieg gewaltig. <br>
        </p>
        <img src="../../../assets/pictures/Urlaub.png" width="400em" class="pic" id="logoUrlaub" alt="verschiedene Bewertungssymbole">
        <p>
            Mittlerweile sind wir an der stetigen Weiterentwicklung unseres Tools und hoffen möglichst vielen Personen das Leben ein bisschen einfacher zu machen. <br>
            Also entscheidet euch gemeinsam für die beste Möglichkeit und genießt gemeinsam das Ergebnis. 
        </p>
        <p>
            Euer Rainer und Achim
        </p>
        <a class="facebook" [href]="'https://www.facebook.com/Decitto-Entscheidet-gemeinsam-104027998473655'" target="_blank" title="Folge uns auf Facebook" alt="Folge uns auf Facebook">
        <img src="../../../assets/logos/Facebook_f_logo_(2019).svg" height="17px" alt="Folge uns auf Facebook"> &nbsp; Folge uns auf Facebook
        </a>
        <p></p>
        <a class="facebook" [href]="'https://www.facebook.com/Spieleraetselde-Exit-Games-umd-Krimi-R%C3%A4tsel-102221805326552'" target="_blank" title="spieleraetsel.de auf Facebook" alt="spieleraetsel.de auf Facebook">
            <img src="../../../assets/logos/Facebook_f_logo_(2019).svg" height="17px" alt="spieleraetsel.de auf Facebook"> &nbsp; Schaue dir auch unsere kostenfreien Exit-Games an
        </a>
    </div>
</div>