<div class="suggestion">
     <app-suggestion-header 
        [topic]="session.topic" 
        [nbuser]="session.nbuser" 
        [userNames]="session.userNames" 
        [symbolChoice]="session.symbolChoice" 
        [ageConfirmation]="session.ageConfirmation"
        [symbolNumber]="session.symbolNumber"
        (newUser)="onNewUser($event)"
        (topicChanged)="onTopicChanged($event)"
        (userNameChanged)="onUserNameChanged($event)"
        (symbolNumberChanged)="onSymbolNumberChanged($event)"
        (userDeletion)="onUserDeleted($event)"
        (mobileViewChange)="onMobileViewChanged($event)"
        (emptyRatingCalculationChanged)="onEmptyRatingCalculationChanged($event)"
        [emptyRatingCalculation]="session.emptyRatingCalculation">
      </app-suggestion-header>
      <app-suggestion 
        *ngFor="let suggestion of session.sugList, let i = index, trackBy: trackItem" 
        [suggestion]="suggestion"
        [mobileViewInformation]="mobileViewInformation"
        (suggestionChange)="onSuggestionChange($event, i)"
        (delete)="onDelete($event, i)">
      </app-suggestion>
      <app-new-suggestion 
        (newSuggestion)="onNewSuggestion($event)" 
        [mobileViewInformation]="mobileViewInformation"
        [nbuser]="session.nbuser">
      </app-new-suggestion>
      <div style="padding-top: 30px;">
        <app-other *ngIf="otherShown"></app-other>
      </div>
  </div>
<div class="transparentOverlay" *ngIf="overlayShown"></div>

<!--Start description-->
<div class="descriptionPage" *ngIf="descriptionPageShown">
  <div class="close" (click)="descriptionPageShown=false; overlayShown=false">
    <img src="../../../assets/buttons/close.svg" width="30px" class="closePic">
  </div>
  <div class="flex" *ngIf="!mobileViewInformation.mobileView">
    <div class="description">
    <img src="../../../assets/pictures/Start Erklärung v3.svg" width="100%">
  </div>
  </div>
  <div class="flex" *ngIf="mobileViewInformation.mobileView">
    <div class="description">
    <img src="../../../assets/pictures/Start Erklärung mobil.svg" width="100%">
  </div>
  </div>
  <div class="flex">
    <h1>Kopiere und speichere den folgenden Link, damit du wieder auf die Session zugreifen kannst und<br>
       teile den Link jetzt oder später mit deinen Mitentscheidern:</h1>
  </div>
  <div class="sessionLink flex">
    <div class="sessionLinkContainer">
    <app-textfield [label]="'Link'" [id]="'sessionLinkField'" [content]="locationString" [readonly]="true" [autoFocus]="true" [fontsize]="'20px'"></app-textfield>
    </div>
    <div >
      <div class="circle-link" title="Link kopieren" (click)="onCopyClick()" rel="noopener">
        <img *ngIf="showCopyLink" id="copy" src="../../../assets/logos/link.svg" alt="Link kopieren" width="22">
        <img *ngIf="!showCopyLink" id="copySuccess" src="../../../assets/logos/success.svg" width="30">
        <svg xmlns="http://www.w3.org/2000/svg" width="24.607" height="23.447" viewBox="0 0 24.607 23.447">
        </svg>
      </div>
    </div>
  </div>
  <div class="flex">
    <div class="understood" (click)="descriptionPageShown=false; overlayShown=false">
    <img src="../../../assets/buttons/Verstanden.svg" id="understoodButton" width="100%">
    </div>
  </div>
  <app-other></app-other>
</div>

<!--Loading Page-->
<div class="flex" id="loadingPage" *ngIf="loadingPageShown">
  <div id="contentContainer">
    <div class="flex" id="progressSpinner">
      <mat-progress-spinner [mode]="'indeterminate'" ></mat-progress-spinner>
    </div>
    <div id="loadingText" *ngIf="!longLoadingTime">
      <p>Inhalte werden geladen...</p>
    </div>
    <div id="loadingTextLong" *ngIf="longLoadingTime">
      <p class="flex" id="loadingText">Das dauert länger als erwartet...</p>
      <p style="text-align: center;">Bitte stelle sicher, dass du eine stabile Verbindung zum Internet hast. <br>
        Sollte das Problem weiterhin bestehen, lade bitte die Seite neu:</p>
      <div class="flex reload" (click)="reloadWindow()">
        <img src="../../../assets/buttons/neuLaden.svg" id="reloadButton" alt="Seite neu laden" width="200px">
      </div>
    </div>
  </div>
</div>