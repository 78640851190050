import { Component, Input, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { NewSessionInformation } from './_interface/new-session-information';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'DecItTo';

  public impressumShown: boolean = false;
  public faqShown: boolean = false;
  public helpShown: boolean = false;
  public aboutShown: boolean = false;
  public startpageShown: boolean = true;
  public mainFeatureAvailable: boolean = false;
  public headerShown: boolean = true;
  public footerShown: boolean = true;
  public sessionString = "";

  public topic: string = "";
  public firstName: string = "";
  public newSessionInformation: NewSessionInformation = {topic: "", name: "", symbolChoice: false, ageConfirmation: false, symbolNumber: 0};

  private appValue = false;

  constructor(private location : Location){}

  ngOnInit(): void {
    var paramStart = this.location.path().indexOf("?id=");
    var appValueStart = this.location.path().indexOf("app=");
    let path =""
    if (paramStart != -1){
      var paramValueEnd = this.location.path().indexOf("&", paramStart);
      if (paramValueEnd != -1){
        this.sessionString = this.location.path().substring(paramStart + 4, paramValueEnd);
      }
      else{
        this.sessionString = this.location.path().substring(paramStart + 4);
      }
      path = this.location.path().substring(1, paramStart);
    }
    else {
    paramStart = this.location.path().indexOf("?");
    if (paramStart != -1){
      path = this.location.path().substring(1, paramStart);
    }
    else {
      path = this.location.path().substring(1);
    }
    }
    let appValueString = "";
    if (appValueStart != -1){
      var appValueEnd = this.location.path().indexOf("&", appValueStart);
      if (appValueEnd === -1){
        appValueString = this.location.path().substring(appValueStart + 4);
      }
      else {
        appValueString = this.location.path().substring(appValueStart + 4, appValueEnd);
      }
    };
    this.appValue = (appValueString == "true");
    if (this.sessionString != ""){
      this.startpageShown= false;
      this.mainFeatureAvailable=true;
    };
    if (path == "impressum"){
      this.impressumShown = true;
      if (this.sessionString != ""){
        this.location.replaceState('?id=' + this.sessionString);
      }
    }
    if (path == "faq"){
      this.faqShown = true;
      if (this.sessionString != ""){
        this.location.replaceState('?id=' + this.sessionString);
      }
    }
    if (path == "about"){
      this.aboutShown = true;
      if (this.sessionString != ""){
        this.location.replaceState('?id=' + this.sessionString);
      }
    }
    if (this.appValue){
      this.footerShown = false;
      this.headerShown = false;
      this.startpageShown = false;
    }
  }
    
  onImpressumStatus(event:boolean): void {
    this.impressumShown = !this.impressumShown;
    this.faqShown = false;
    this.helpShown = false;
    this.aboutShown = false;
  }

  onFaqStatus(event:boolean): void {
    this.faqShown = !this.faqShown;
    this.impressumShown = false;
    this.helpShown = false;
    this.aboutShown = false;
  }

  onHelpStatus(event:boolean): void {
    this.helpShown = !this.helpShown;
    this.impressumShown = false;
    this.faqShown = false;
    this.aboutShown = false;
  }

  onFaqChange(event:boolean): void {
    this.helpShown = false;
    this.impressumShown = false;
    this.faqShown = true;
    this.aboutShown = false;
  }

  onAboutStatus(event:boolean): void {
    this.aboutShown = !this.aboutShown;
    this.impressumShown = false;
    this.helpShown = false;
    this.faqShown = false;
  }

  onStartpageStatus(event: NewSessionInformation): void {
    this.startpageShown = false;
    this.newSessionInformation = event;
    this.mainFeatureAvailable = true;
  }
}


