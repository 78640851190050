import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  @Output()
  public impressumClosed = new EventEmitter<boolean>();

  public adressShown = false;
  public callNumber = false;

  private emailstring="mailto:kontakt@decitto.de?Subject=Anfrage zu DecItTo!&body=Liebes DecItTo!-Team, %0D%0A%0D%0A  ";
  
  constructor(private metaService:Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.metaService.updateTag( { name:'description',content:"Impressum von DecItTo! - Vorschläge bewerten und gemeinsam Entscheidung treffen mit dem Online-Tool DecItTo!"}, "name='description'");
    this.titleService.setTitle("DecItTo! | Impressum");
  }

  onClickClose(): void{ 
    this.impressumClosed.emit(false);
  }

  onMailClicked(): void{ 
    window.open(this.emailstring, '_blank');
  }
}
