<div *ngIf="!mobileView" class="ad" id="adTop">
    <ng-adsense
        [display]="'inline-block'"
        [height]="120"
        [fullWidthResponsive]="false"
        [adSlot]="7909596568"
        [width]="800"
        [adFormat]="'horizontal'"
    ></ng-adsense>
</div>
<div *ngIf="mobileView" class="adMobile" id="adMobileTop">
    <ng-adsense
        [display]="'inline-block'"
        [height]="120"
        [width]="450"
        [fullWidthResponsive]="false"
        [adSlot]="9726122991"
        [adFormat]="'horizontal'"
    ></ng-adsense>
</div>
<div class="flex">
    <div *ngIf="!mobileView" class="ad" id="adLeft">
        <ng-adsense
            [display]="'inline-block'"
            [adFormat]="'vertical'"
            [fullWidthResponsive]="false"
            [adSlot]="7971168137"
            [width]="120"
            [height]="800"
        ></ng-adsense>
    </div>
    <app-main-feature
        id="app"
        [newSessionInformation]="newSessionInformation"
        [sessionString]="sessionString"
        (mobileViewEvent)="mobileView=$event">
    </app-main-feature>
</div>
<div *ngIf="mobileView" class="adMobile" id="adMobile">
    <ng-adsense
        [display]="'block'"
        [height]="120"
        [width]="450"
        [fullWidthResponsive]="true"
        [adSlot]="4750495461"
        [adFormat]="'auto'"
    ></ng-adsense>
</div>
