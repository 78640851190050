<div class="page"
    [class.preventTopZIndex]="preventTopZIndex" 
    [class.TopZIndex]="!preventTopZIndex" 
    [style.width]="width+'rem'"
    [style.height]="height+'rem'"
    [id]="id"
    (keyup.escape)="onCloseClick()">
    <div class="close" (click)="onCloseClick()">
        <img src="../../../assets/buttons/close.svg" width="30px" class="closePic">
    </div>
    <h1 [innerHTML] ="question"></h1>
    <div class="textfield" *ngIf="normalMode">
        <app-textfield
            [placeholder]="placeholder"
            [label]="placeholder"
            [id]="id+'_input'"
            (change)="onNewContent($event)"
            (enter)="onEnter($event)"
            [fontsize]="'20px'"
            [maxlength]="maxlength"
            [content]="initialContent"
            [autoFocus]="true"
            [floatingLabels]="floatingLabels"
        ></app-textfield>
    </div>
    <div *ngIf="textarea">
        <textarea
        class="textarea"
        type="text" 
        [id]="id+'_input'"
        [style.font-size]="'20px'"
        [maxLength]="500" 
        [value]="initialContent" 
        [placeholder]="placeholder" 
        (keyup)="onNewContent(input.value)" 
        #input>
        </textarea>
    </div>
    <div class="textfield" *ngIf="multipleTextfields">
        <app-textfield
            *ngFor="let textfieldContent of initialContentList, let i = index"
            [placeholder]="placeholder + (i+1)"
            [label]="placeholder + (i+1)"
            [id]="id+'_input_'+i"
            (change)="onNewContentArray($event, i)"
            (enter)="onEnterArray($event, i)"
            [fontsize]="'20px'"
            [maxlength]="maxlength"
            [content]="newContentList[i]"
            [autoFocus]="i==0"
            [floatingLabels]="floatingLabels"
        ></app-textfield>
    </div>
    <div class="singleChoice" *ngIf="singleChoice">
        <mat-radio-group
            class="singleChoiceGroup">
            <mat-radio-button class="singleChoiceValue" name="singleChoice" id="{{id}}_input_{{i}}" *ngFor="let content of initialContentList, let i = index" [value]="content" (change)="onSingleChoiceChange($event, i)">
                {{content}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div 
        class="logo"
        (click)="onAddClick()"
        *ngIf="!confirmQuery">
        <img src="../../../assets/buttons/Bestätigen.svg" id="acceptbutton" width="170rem">
    </div>
    <div *ngIf="confirmQuery" class="flex">
        <div
            class="logo" 
            (click)="onAddClick()">
            <img src="../../../assets/buttons/Bestätigen.svg" id="acceptbutton" width="170rem">
        </div>
        <div
            class="logo" 
            (click)="onCloseClick()">
            <img src="../../../assets/buttons/Abbrechen.svg" id="acceptbutton" width="170rem">
        </div>
    </div>
</div>
<div class="transparentOverlay"></div>