import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-template-footer',
  templateUrl: './template-footer.component.html',
  styleUrls: ['./template-footer.component.scss']
})
export class TemplateFooterComponent implements OnInit {

  @Output() 
  public impressumShow = new EventEmitter<boolean>();

  @Output() 
  public faqShow = new EventEmitter<boolean>();

  @Output() 
  public helpShow = new EventEmitter<boolean>();

  @Output() 
  public aboutShow = new EventEmitter<boolean>();

  private emailstring="mailto:kontakt@decitto.de?Subject=Anfrage zu DecItTo!&body=Liebes DecItTo!-Team, %0D%0A%0D%0A  ";

  constructor() { }

  ngOnInit(): void {
  }

  onImpressumClicked(){
    this.impressumShow.emit(true);
  }

  onKontaktClicked(){
    window.open(this.emailstring, '_blank');
  }

  onFAQClicked(){
    this.faqShow.emit(true);
  }

  onHelpClicked(){
    this.helpShow.emit(true);
  }

  onAboutClicked(){
    this.aboutShow.emit(true);
  }

}
