import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {NgcCookieConsentService, NgcInitializeEvent} from 'ngx-cookieconsent';
import { NewSessionInformation } from '../../_interface/new-session-information';
import { Subscription } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {

  @Output()
  public newSessionStart = new EventEmitter<NewSessionInformation>();
  newSessionInformation: NewSessionInformation = {topic: "", name: "", symbolChoice: false, ageConfirmation: false, symbolNumber: 0};

  public newSessionShown = false;
  public topic = "";
  public name ="";
  public symbolChoice = false;
  public ageConfirmation = false;
  public symbolNumber = 0;
  public symbolURL = "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e";
  
  private popupCloseSubscription: Subscription = new Subscription;
  private initializeSubscription: Subscription = new Subscription;
  private emailstring="mailto:kontakt@decitto.de?Subject=Anfrage zu DecItTo!&body=Liebes DecItTo!-Team, %0D%0A%0D%0A  ";

  @HostBinding("attr.style")
  public get getSymbolURL(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--symbolPicture: url(\"${this.symbolURL}\"); `);
  }
  
  constructor(private ccService: NgcCookieConsentService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    let destroyed = false;
    if (this.ccService.hasConsented()){
      this.ccService.destroy();
      destroyed = true;
    };
    if (!destroyed){
      if (this.ccService.hasAnswered()){
        this.ccService.clearStatus();
        this.ccService.open();
      };
      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        () => {
          this.ccService.destroy();
        });
    };
  }

  onStartbuttonClicked() :void {
    this.newSessionShown = true;
    this.ccService.destroy();
  }
  
  onNewTopic(event: string):void {
    this.topic = event;
  }

  onNewName(event: string):void {
    this.name = event;
  }

  onSymbolCheckboxChange(event: any):void {
    this.symbolChoice = event.checked;
    if(!event.checked){
      let info:any = {value: Number};
      info.value = 0;
      this.onSymbolChoiceChange(info);
    }
  }

  onAgeConfirmationChange(event: any):void {
    this.ageConfirmation =  event.checked;
  }

  onSymbolChoiceChange(event: any):void {
    this.symbolNumber = +event.value;
    switch (+this.symbolNumber){
      case 0:
        this.symbolURL = "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e";
        break;
      case 1:
        this.symbolURL = "../../../assets/ratingSymbols/ausrufezeichenChecked.svg";
        break; 
      case 2:
        this.symbolURL = "../../../assets/ratingSymbols/bierChecked.svg";
        break;
      case 3:
        this.symbolURL = "../../../assets/ratingSymbols/weinChecked.svg";
        break;
      case 4:
        this.symbolURL = "../../../assets/ratingSymbols/cocktailChecked.svg";
        break;
      case 5:
        this.symbolURL = "../../../assets/ratingSymbols/sunbedChecked.svg";
        break;
      case 6:
        this.symbolURL = "../../../assets/ratingSymbols/sunsetChecked.svg";
        break;
      case 7:
        this.symbolURL = "../../../assets/ratingSymbols/climbingChecked.svg";
        break;
      case 8: 
        this.symbolURL = "../../../assets/ratingSymbols/adultMaleStandard.png";
        break;
      case 9: 
        this.symbolURL = "../../../assets/ratingSymbols/adultFemaleChecked.svg";
        break;
      case 10: 
        this.symbolURL = "../../../assets/ratingSymbols/footballChecked.svg";
        break;
      case 11: 
        this.symbolURL = "../../../assets/ratingSymbols/LGBTQ_Checked.svg";
        break;
      case 12: 
        this.symbolURL = "../../../assets/ratingSymbols/PrideHeartChecked.svg";
        break;
      default:
        break;
    }
  }

  onAcceptClicked(event?: any):void {
    this.newSessionStart.emit({topic: this.topic, name: this.name, symbolChoice: this.symbolChoice, ageConfirmation: this.ageConfirmation, symbolNumber: this.symbolNumber});
  }

  onKontaktClicked(){
    window.open(this.emailstring, '_blank');
  }

  ngOnDestroy() {
    this.popupCloseSubscription.unsubscribe();
  }
}
