import { Component, OnInit, Output, EventEmitter, Input, HostBinding} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MobileViewInformation } from '../../_interface/mobile-view-information';

@Component({
  selector: 'app-new-suggestion',
  templateUrl: './new-suggestion.component.html',
  styleUrls: ['./new-suggestion.component.scss']
})
export class NewSuggestionComponent implements OnInit {

  @Input()
  public nbuser: number = 0;

  @Input()
  public mobileViewInformation: MobileViewInformation;

  @Output()
  public newSuggestion = new EventEmitter<string>();

  public addLabel = "";
  public suggestionWidth = "";

  @HostBinding("attr.style")
  public get getSuggestionWidth(): any {
    this.calculateWidth();
    return this.sanitizer.bypassSecurityTrustStyle(`--suggestionWidth: ${this.suggestionWidth}; `);
  }
  
  constructor(private sanitizer: DomSanitizer) {
    this.mobileViewInformation ={
      mobileView: false,
      mobileUserShown: 0,
      screenHeight: 0,
      screenWidth: 0,
    };
   }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.calculateWidth();
  }

  onContentChange(event: string): void{
    this.addLabel = event;
  }

  onAddButton(event?: any): void{
    this.newSuggestion.emit(this.addLabel);
    this.addLabel = "";
  }

  onEnter(event: string): void{
    this.addLabel = event;
    this.newSuggestion.emit(this.addLabel);
    this.addLabel = "";
  }

  private calculateWidth(): void{
    if (!this.mobileViewInformation.mobileView){
      this.suggestionWidth = (50 + 13.5*this.nbuser) + "rem";
    }
    else if (this.mobileViewInformation.mobileView){
      this.suggestionWidth = "28rem";
    }
  }
}
