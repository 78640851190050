<div class="container" [class.containerMobile]="mobileViewInformation.mobileView">
    <div class="box" [ngClass]="{'ranking': !mobileViewInformation.mobileView , 'rankingMobile' : mobileViewInformation.mobileView }">
        <div class="flex" [class.settingButtonMobile]="mobileViewInformation.mobileView">
            <img id="settingButton" src="../../../assets/buttons/die-einstellungen.svg" height="30px" (click)="settingBoxShown=!settingBoxShown" title="Einstellungen" alt="Einstellungen">
        </div>
    </div>
    <div class="box" [ngClass]="{'text': !mobileViewInformation.mobileView , 'textMobile' : mobileViewInformation.mobileView }">
        <div [innerHTML]="topicText" id="topic"></div>
    </div>
    <div class="boxContainer" *ngIf="!mobileViewInformation.mobileView">
    <div class="box ratingName" *ngFor="let user of userNames, let i = index" [id]="'User_'+i" [innerHTML]="userNames[i]"></div>
    </div>
    <div class="boxContainer" *ngIf="mobileViewInformation.mobileView">
        <mat-form-field class="ratingNameMobile no-wrapper" appearance="outline" id="nameChoiceSelect" name="nameChoice">
            <mat-label>Namen wählen</mat-label>
            <mat-select name="symbolChoice" [(ngModel)]="nameChoice" (selectionChange)="onMobileUserChoiceChange($event)">
                <mat-option *ngFor="let user of userNames, let i = index" [value]="i" [innerHTML]="userNames[i]" [id]="'User_'+i"></mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <img [class.addButtonMobile]="mobileViewInformation.mobileView" class="addButton" height="30px" title="Benutzer hinzufügen" src="../../../assets/buttons/+.svg" (click)="nameInputShown=true">
</div>
<div class="transparentOverlay" *ngIf="settingBoxShown"></div>

<!--Communication Box for new user-->
<app-communication-box 
*ngIf="nameInputShown"
[question]="'Name des neuen Mitentscheiders:'"
[placeholder]="'Name Mitentscheider'"
[id]="'Name Mitentscheider'"
(contentChange)="onNewName($event)"
[maxlength]="16"
(close)="nameInputShown=false">
</app-communication-box>

<!--Settings Menü-->
<div 
*ngIf="settingBoxShown"
class="settingBoxContainer">
    <div class="settingBox">
        <div class="close" (click)="onSettingMenuClosed()">
            <img src="../../../assets/buttons/close.svg" width="30px" class="closePic">
        </div>
        <div style="margin-bottom: 20px;">
            <div>
                <h1>Einstellungen</h1>
            </div>
        </div>
        <div class="flexLeft optionPlace" id="changeTopic">
            <a (click)="onChangeTopic()">
                <img title="Thema bearbeiten" src="../../../assets/buttons/bleistift.svg" width="12" style="padding-top: 4px;"> 
                &nbsp; Thema bearbeiten</a>
        </div>
        <div class="flexLeft optionPlace" id="changeNames">
            <a (click)="onChangeNames()">
                <img title="Namen bearbeiten" src="../../../assets/buttons/bleistift.svg" width="12" style="padding-top: 4px;"> 
                &nbsp; Namen der Mitentscheider bearbeiten</a>
        </div>
        <div class="flexLeft optionPlace" id="deleteUser">
            <a (click)="onDeleteUser()">
                <img title="Mitentscheider löschen" src="../../../assets/buttons/loschen.svg" width="12" style="padding-top: 4px;"> 
                &nbsp; Mitentscheider löschen</a>
        </div>
        <div class="flexLeft optionPlace">
            <div id="symbolChange">
                <p>Sollen leere Bewertungen in die Gesamtbewertung eingerechnet werden?</p>
            </div>
            <div class="checkbox">
                <mat-slide-toggle [checked]="emptyRatingCalculation" (change)="onCalculationCheckboxChange($event)"></mat-slide-toggle>
            </div>
        </div>
        <div class="flexLeft optionPlace" *ngIf="!symbolChoice">
            <div id="symbolChange">
                <p>Soll es möglich sein andere Bewertungssymbole auszuwählen?</p>
            </div>
            <div class="checkbox">
                <mat-slide-toggle (change)="onSymbolCheckboxChange($event)"></mat-slide-toggle>
            </div>
        </div>
        <div *ngIf="symbolChoice">
            Bewertungssymbole ändern:
        </div>
        <div class="flexLeft optionPlace" *ngIf="symbolChoice">
            <div id="symbolChoiceText">
                <p>Auswahl neues Symbol:</p>
            </div>
            <div>
                <mat-form-field appearance="standard" id="symbolChoiceSelect">
                    <mat-label>Symbol wählen</mat-label>
                    <mat-select name="symbolChoice" (selectionChange)="onSymbolChoiceChange($event)">
                        <mat-option value=0>Sterne</mat-option>
                        <mat-option value=1>Ausrufezeichen</mat-option>
                        <mat-option value=10>Fußball</mat-option>
                        <mat-option value=2>Bier</mat-option>
                        <mat-option value=3>Wein</mat-option>
                        <mat-option value=4>Cocktail</mat-option>
                        <mat-option value=5>Strand</mat-option>
                        <mat-option value=6>Sonnenuntergang</mat-option>
                        <mat-option value=7>Bergsteigen</mat-option>
                        <mat-option value=11>Pride Flagge</mat-option>
                        <mat-option value=12>Pride Herz</mat-option>
                        <mat-option value=8 *ngIf="ageConfirmation">Mann</mat-option>
                        <mat-option value=9 *ngIf="ageConfirmation">Frau</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="checkbox" id="symbolPicture"></div>
        </div>
        <div class="flexLeft optionPlace" *ngIf="mobileViewInformation.mobileView || !mobileViewPossible">
            <div id="symbolChange">
                <p>Mobile Ansicht deaktivieren (Smartphone Porträtmodus)<br>(Gültig bis Aktualisierung der Seite)</p>
            </div>
            <div class="checkbox">
                <mat-slide-toggle [checked]="!mobileViewPossible" (change)="onMobileViewPossibilityChange($event)"></mat-slide-toggle>
            </div>
        </div>
        <div class="confirm">
        <div class="confirm" (click)="onConfirmClicked()">
            <img src="../../../assets/buttons/Bestätigen.svg" id="confirmbutton" width="170rem" height="80rem">
        </div>
        <div class="confirm" (click)="onSettingMenuClosed()">
        <img src="../../../assets/buttons/Abbrechen.svg" id="declinebutton" width="170rem" height="80rem">
        </div>
        </div>
    </div>
</div>

<!--Communication Box for topic change-->
<app-communication-box 
*ngIf="topicChangeShown"
[question]="'Ändere die Beschreibung des Themas:'"
[placeholder]="'Thema'"
[initialContent]="topic"
[id]="'topicChange'"
(contentChange)="onChangedTopic($event)"
[maxlength]="25"
(close)="topicChangeShown=false">
</app-communication-box>

<!--Communication Box for user name change-->
<app-communication-box 
*ngIf="userNameChangeShown"
[question]="'Ändere die Namen der Mitentscheider:'"
[placeholder]="'Name Mitentscheider '"
[initialContentList]="userNames"
[id]="'userNameChange'"
(contentListChanged)="onChangedUserNames($event)"
[maxlength]="16"
(close)="userNameChangeShown=false"
[multipleTextfields]="true"
[floatingLabels]="true">
</app-communication-box>

<!--Communication Box for user deletion-->
<app-communication-box 
*ngIf="userDeletionShown"
[question]="'Der Mitentscheider wird hiermit unwiderruflich gelöscht. Wähle den zu löschenden Mitentscheider:'"
[initialContentList]="userNames"
[id]="'userNameDelete'"
(singleChoiceSelected)="onDeletedUser($event)"
(close)="userDeletionShown=false"
[singleChoice]="true">
</app-communication-box>

<!--Communication Box for user selection in mobile View-->
<app-communication-box 
*ngIf="userSelectionShown && mobileViewInformation.mobileView"
[question]="'Wähle deinen Namen oder &quotBenutzer hinzufügen&quot!'"
[initialContentList]="userNamesSelection"
[id]="'userNameSelection'"
(singleChoiceSelected)="onFirstUserChoiceChange($event)"
(close)="userSelectionShown=false; firstMobileViewStart=false"
[singleChoice]="true"
[preventTopZIndex]="true">
</app-communication-box>