// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAyCr1NrOOrIsz2pbmLfBE5MXrYycuMHfA",
    authDomain: "decitto-f7a63.firebaseapp.com",
    databaseURL: "https://decitto-f7a63-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "decitto-f7a63",
    storageBucket: "decitto-f7a63.appspot.com",
    messagingSenderId: "790638641619",
    appId: "1:790638641619:web:49f318358bc7e86bc2ef26",
    measurementId: "G-Z3Y16VPQT1"
  },
  cookieConfig: {
    cookie: {
      domain: 'decitto.de' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    palette: {
      popup: {
        background: '#34302D',
        text: "#ffffff",
        link: "#ffffff"
      },
      button: {
        background: '#ffd044',
        text: "#000000",
        border: "transparent"
      }
    },
    revokable: false,
    position: "bottom-left",
    theme: 'classic',
    type: 'opt-out',
    content: {
      message: "Wir benutzen Cookies, damit wir Dienste zur Analyse und Verbesserung nutzen und diese Website kostenfrei anbieten können. ",
      dismiss: "Verstanden!",
      deny: "Cookies ablehnen",
      link: "Weitere Informationen",
      href: "/impressum",
      policy: "Cookie Einstellungen",
      allow: "Akzeptieren!"
    },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
