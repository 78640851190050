import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-rating',
  templateUrl: './user-rating.component.html',
  styleUrls: ['./user-rating.component.scss']
})
export class UserRatingComponent implements OnInit {

  @Input()
  public userSugId = "";

  public ratingId = "";

  @Input()
  public rating = 0;

  @Input()
  public userComment = "";

  @Input()
  public inputUsable = true;
  
  @Input()
  public symbolNumber = 0;

  @Output()
  public ratingChange= new EventEmitter<number>();

  @Output()
  public userCommentChange= new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    this.ratingId = this.userSugId + "_rating";
  }

  onChangeRating(event: any): void{
    this.ratingChange.emit(event);
    this.rating=event;
  }

  onUserComment(event: string): void{
    this.userCommentChange.emit(event as string);
    this.userComment=event;
  }
}
