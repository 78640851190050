import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit {

  @Output()
  public helpClosed = new EventEmitter<boolean>();

  @Output()
  public toFaqChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickClose(): void{ 
    this.helpClosed.emit(false);
  }

  toFaqChangeClicked(): void{ 
    this.toFaqChange.emit(true);
  }
}
