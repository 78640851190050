<div class="page" id="start-page">
    <div id="firstScreen">
        <div class="headlineSection flex">
            <div class="flex" id="logoMitText" >
                <div id="logo">
                    <img src="../../../assets/pictures/Logo mit Text.svg" alt="DecItTo! - Entscheidet gemeinsam" width="70%">
                </div>    
            </div>
        <div id="headlines">
            <div id="headlinesText">
                <h2>Gemeinsam eine Entscheidung zu treffen ist nicht immer leicht.</h2>
                <h3>Jetzt mit einem Klick Vorschläge bewerten und gemeinsam die beste Entscheidung treffen mit <span style="font-weight:normal">DecItTo!</span></h3>
            </div>
        </div>
            </div>    
    <div class="screenshotSection flex">
        <div id="description" class="flex">
            <div id="descriptionContainer" class="flex">
            <img src="../../../assets/pictures/Erklärung.svg" alt="In nur drei Schritten DecItTo! nutzen" width="80%">
            </div>
        </div>
        <div class="flex" id="screenshot" >
            <div id="screenshotContainer">
            <img src="../../../assets/pictures/Grafik Computer_final.png" alt="Übersichtliche Ansicht der Vorschläge, um gemeinsam zu entscheiden" width="95%">
            </div>
        </div>
    </div>
    <div 
        class="flex startbuttonArea"
        (click)="onStartbuttonClicked()">
        <img src="../../../assets/buttons/LosGehts.svg" alt="Starten" id="startbutton" width="300rem">
    </div>
    <div class="flex startbuttonArea" id="characteristics">
        <h4><span style="font-size:14px">ohne Anmeldung & kostenfrei nutzbar</span><br>Mit dem Klick auf "Los geht's!" wird das Setzen von Cookies<br>
            für Dienste zur Analyse und Verbesserung dieser Website zugestimmt.
        </h4>
    </div>
    <div class="other flex ">
        <div id="scrollDown">
            <a href="#contentHeadline"><span></span></a>
        </div>
    </div>
    </div>
    <div id="content">
        <div id="newInformationHeadline2" class="sectionHeadlines" *ngIf=false>
            <h2>NEU: Pride Season</h2>
            <div class="flex sectionHeadlineText">
                <p>
                Pünktlich zur Pride Season stehen zwei neue Bewertungssymbole zur Auswahl.<br/>
                Einfach Session erstellen --> Link mit Freunden teilen --> Abstimmen.   
                </p>
            </div>
        </div>
        <div class="flex" *ngIf=false>
        <div class="tableContainer" style="padding-bottom: 0;">
            <div class="tableItem" id="tableItem22">
                <div class="tablePicture flex" id="tablePicture1">
                    <img src="../../../assets/ratingSymbols/LGBTQ_Checked.svg" alt="Pride Flag Symbol" width="120px" height="120px">
                </div>
                <div class="tableText" id="tableText221">
                    <h5>Pride Flag</h5>
                </div>
            </div>
            <div class="tableItem" id="tableItem22">
                <div class="tablePicture flex" id="tablePicture1">
                    <img src="../../../assets/ratingSymbols/PrideHeartChecked.svg" alt="Pride Heart Symbol" width="120px" height="120px">
                </div>
                <div class="tableText" id="tableText221">
                    <h5>Pride Heart</h5>
                </div>
            </div>
        </div>
        </div>
        <div class="secondBackground">
        <div id="contentHeadline" class="sectionHeadlines">
            <h2>Gemacht, um Entscheidungen leichter gemeinsam zu treffen</h2>
            <div class="flex sectionHeadlineText">
                <p>
                Ob den nächsten Urlaub, einen Tagesausflug, die nächste Party Location oder das nächste Teamevent. 
                Mit DecItTo! kann jeder Teilnehmer seine Vorschläge einbringen. Anschließend können die Vorschläge von allen Mitentscheidern bewertet und kommentiert werden. 
                Die Vorschläge werden automatisch nach dem Durchschnitt der Bewertungen sortiert und können so für eine gemeinsame Entscheidung herangezogen werden.
                Auch im Meeting, als Brainstorming Liste oder bei dem nächsten Strategie Workshop ergänzt DecItTo! den Entscheidungsprozess ideal. 
                Komplexe Vorschläge lassen sich durch die Einbindung einer Beschreibung und das Einfügen eines Links ohne Probleme einbringen.
                DecItTo! ist ohne Anmeldung und kostenfrei nutzbar.  
                </p>
            </div>
        </div>
        <div class="flex">
        <div class="tableContainer">
            <div class="tableItem" id="tableItem1">
                <div class="tablePicture flex" id="tablePicture1">
                    <img src="../../../assets/pictures/Kommentar.png" alt="Jeder bewertet und kommentiert jeden Vorschlag einzeln" width="60%" height="auto">
                </div>
                <div class="tableText" id="tableText1">
                    <h5>Sinnlose Diskussionen sind nicht mehr nötig!</h5>
                    <p>
                        Jeder bewertet und kommentiert alle vorhandenen Vorschläge einzeln.<br/>
                        Entscheidet euch gemeinsam ganz rational für den am besten bewerteten Vorschlag.
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem2">
                <div class="tablePicture flex" id="tablePicture2">
                    <img src="../../../assets/pictures/Hinzufügen.png" alt="Benutzer und Vorschläge sind leicht hinzuzufügen" width="80%" height="auto">
                </div>
                <div class="tableText" id="tableText2">
                    <h5>Füge einfach neue Mitentscheider und Vorschläge hinzu</h5>
                    <p>
                        Neue Mitentscheider und neue Vorschläge können auch später ganz einfach eurer Liste hinzugefügt werden.
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem3">
                <div class="tablePicture flex" id="tablePicture3">
                    <img src="../../../assets/pictures/Links+Beschreibung.png" alt="Vorschläge können mit Links und Beschreibungen erläutert werden" width="80%" height="auto">
                </div>
                <div class="tableText" id="tableText3">
                    <h5>Beschreibe Vorschläge mit Links und Text</h5>
                    <p>
                        Beschreibe die vorhandenen Vorschläge mit einem individuellen Text genauer und hinterlege einen Link, um  
                        sich näher über den Vorschlag zu informieren. 
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem4">
                <div class="tablePicture flex" id="tablePicture4">
                    <img src="../../../assets/pictures/Ausgewählt+Nm.png" alt="Markierung von Vorschlägen mit Ausgewählt und nicht möglich" width="80%" height="auto">
                </div>
                <div class="tableText" id="tableText4">
                    <h5>Markiere als "Ausgewählt!" oder "Nicht möglich!"</h5>
                    <p>
                        Markiere einen bereits ausgwählten Vorschlag als "Ausgewählt!", wenn die Umsetzung
                        bereits feststeht.<br/>
                        Markiere einen Vorschlag als "Nicht möglich!", wenn der Vorschlag
                        nicht mehr umgesetzt werden kann.
                    </p>
                </div>
            </div>
        </div>
        </div>
        </div>
        <div id="possibilityHeadline" class="sectionHeadlines">
            <h2>Ein Tool, flexible Möglichkeiten</h2>
            <div class="flex sectionHeadlineText">
                <p>
                DecItTo! lässt sich für die verschiedensten Dinge einsetzen. Von der Einzelnutzung bis hin zu großen Teams oder im Freundeskreis, lassen sich Listen gemeinsam verwalten und über die verschiedenen Vorschläge 
                abstimmen. So kann DecItTo! als strukturierter Wissensspeicher für eine bevorstehende Eintscheidung oder als kurzfristige Abfragemöglichkeit genutzt werden. Sowohl für gemeinsame Abstimmungen oder 
                für den alleinigen Entscheidungsprozess ist DecItTo! ideal geeignet. Für jeden der folgenden Einsatzszenarien ist ein Demo Beispiel zum Testen von DecItTo! hinterlegt. 
                </p>
            </div>
        </div>
        <div class="flex">
        <div class="tableContainer" id="possibilityTableContainer">
            <div class="tableItem" id="tableItem1">
                <div class="tablePicture flex" id="tablePicture1">
                    <img src="../../../assets/pictures/friends.svg" alt="Im Freundeskreis Vorschläge bwerten und gemeinsam die beste Entscheidung treffen" width="40%" height="100%">
                </div>
                <div class="tableText" id="tableText1">
                    <h5>Entscheidungen im Freundeskreis</h5>
                    <p>
                        Wo wollen wir nächsten Samstagabend hin gehen?<br/>
                        Welche Bar findet ihr am besten? <br/>
                        Wo wollen wir das nächste Mal grillen?<br/>
                        Gemeinsame Abstimmungen sind nun kein Problem mehr. 
                        <a class="link" [href]="'https://decitto.de/?id=-MYqV8MgK-8Xcm21O7jA'" target="_blank" title="Beispiel für eine Entscheidung mit Freunden ansehen" alt="Beispiel für eine Entscheidung mit Freunden ansehen">Beispiel mit Freunden ansehen</a>
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem2">
                <div class="tablePicture flex" id="tablePicture2">
                    <img src="../../../assets/pictures/round-table.svg" alt="Alternativen im Büro bewerten und gemeinsam abstimmen" width="30%" height="100%">
                </div>
                <div class="tableText" id="tableText2">
                    <h5>Abstimmungen während eines Meetings</h5>
                    <p>
                        Ist Vorschlag A oder Vorschlag B der beste? Und was sind die Vorteile der beiden Möglichkeiten?<br/>
                        Verschiedene Personen haben verschiedene Meinungen zu den einzelnen Alternativen. Jetzt leicht abfragen und visualisieren lassen mit DecItTo!
                        <a class="link" [href]="'https://decitto.de/?id=-ManKbZaCcYcly-H94TF'" target="_blank" title="Beispiel für eine Entscheidung im Büro ansehen" alt="Beispiel für eine Entscheidung im Büro ansehen">Beispiel im Meeting ansehen</a>
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem3">
                <div class="tablePicture flex" id="tablePicture3">
                    <img src="../../../assets/pictures/buy-online.svg" alt="Vor dem nächsten Kauf DecItTo! als Wissenssammlung nutzen und die beste Alternative kaufen" width="30%" height="100%">
                </div>
                <div class="tableText" id="tableText3">
                    <h5>Entscheidungen beim Produktkauf</h5>
                    <p>
                        Welcher Monitor soll es als nächstes sein? Welcher Fernseher kommt in Frage? Welche Küche / Herd oder was für ein neues Auto soll es sein?
                        Jedes Produkt hat gewisse Vor- und Nachteile. Jetzt DecItTo! als Wissenssammlung nutzen und sich einfach an die beste Alternative heranführen. 
                        <a class="link" [href]="'https://decitto.de/?id=-MYq_7U7uWtnBPpbMh9m'" target="_blank" title="Beispiel für den nächsten Monitorkauf ansehen" alt="Beispiel für den nächsten Monitorkauf ansehen">Beispiel für Monitorkauf ansehen</a>
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem4">
                <div class="tablePicture flex" id="tablePicture4">
                    <img src="../../../assets/pictures/family.svg" alt="Entscheidungen in der Familie ganz demokratisch abtsimmen" width="30%" height="100%">
                </div>
                <div class="tableText" id="tableText4">
                    <h5>Alltagsabstimmungen in der Familie</h5>
                    <p>
                        Welches Essen soll es bei der nächsten Familienzusammenkunft geben oder in welches Restaurant wollen wir gehen?
                        Was für ein Geschenk sollen wir gemeinsam schenken? Ganz einfach die verschiedenen Vorschläge bewerten und gemeinsam die beste Entscheidung treffen. 
                        <a class="link" [href]="'https://decitto.de/?id=-MYqb-onY_nXk8CMqJyY'" target="_blank" title="Beispiel zur Entscheidung über einen Kuchen ansehen" alt="Beispiel zur Entscheidung über einen Kuchen ansehen">Beispiel in der Familie ansehen</a>
                    </p>
                </div>
            </div>
        </div>
         </div>
        <div class="secondBackground" id="funSection">
        <div id="funHeadline" class="sectionHeadlines">
            <h2>Entscheidungen können auch Spaß machen</h2>
            <div class="flex sectionHeadlineText">
                <p>
                Damit das Bewerten und Auswählen eines Vorschlags noch mehr Spaß macht, lassen sich die Bewertungssymbole je nach Themenfeld anpassen. Von den klassischen Sternen über den Büroalltag mit Feierabend bis 
                hin zu einem gemütlichen Urlaubsfeeling gibt es für jede Situation das passende Symbol. Für die Auswahl von Dingen für Erwachsene gibt es eine ganz besondere Auswahl an Symbolen zur Bewertung. 
                </p>
            </div>
        </div>
        <div class="flex">
        <div class="tableContainer">
            <div class="tableItem" id="tableItem1">
                <div class="tablePicture flex" id="tablePicture1">
                    <img src="../../../assets/pictures/Klassiker.png" alt="Ein Stern ist der Klassiker unter den Symbolen für eine Bewertung"  width="auto" height="40%">
                </div>
                <div class="tableText" id="tableText1">
                    <h5>Der Klassiker!</h5>
                    <p>
                        Sobald man an Bewertungen denkt kommen einen Sterne ins Gedächtnis.<br/>
                        Bekannt von Bewertungen aller Art. <br/>
                        Warum nicht auch Vorschläge bewerten?
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem2">
                <div class="tablePicture flex" id="tablePicture2">
                    <img src="../../../assets/pictures/Büro mit Feierabend.png" alt="Symbole für den Büroalltag und den Feierabend"  width="80%" height="auto">
                </div>
                <div class="tableText" id="tableText2">
                    <h5>Büroalltag und Feierabend</h5>
                    <p>
                        Entscheidungen im Büro sind allgegenwärtig. Warum nicht ein bisschen Abwechslung in den Entscheidungsprozess bringen?<br/>
                        Zu jedem Bürotag gehört doch auch der Feierabend. Von einem gemütlichen Bier, über Wein und einen Coktail ist alles dabei.
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem3">
                <div class="tablePicture flex" id="tablePicture3">
                    <img src="../../../assets/pictures/Urlaub.png" alt="Symbole für die Vorfreude auf den Urlaub"  width="80%" height="auto">
                </div>
                <div class="tableText" id="tableText3">
                    <h5>Urlaubsfreude</h5>
                    <p>
                        Die Vorfreude auf den Urlaub wächst und wächst. 
                        Aber welches Hotel, welchen Strand, welches Zimmer, welcher Mietwagen und welche Tagesausflüge buchen wir jetzt?
                        Mit DecItTo! fällt die Entscheidung gar nicht mehr so schwer.
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem21">
                <div class="tablePicture flex" id="tablePicture1">
                    <img src="../../../assets/ratingSymbols/footballChecked.svg" alt="Fußball Symbol" width="120px" height="120px">
                </div>
                <div class="tableText" id="tableText1">
                    <h5>Fußball</h5>
                    <p>
                        Entscheidet gemeinsam welche Mannschaft als nächstes gewinnen wird. 
                        <a class="link" [href]="'https://decitto.de/?id=-Mbus-7mExEuQWWmSvPP'" target="_blank" title="Beispiel zur Fußball EM 2021" alt="Beispiel zur Fußball EM 2021">Wer wird Fußball Weltmeister 2022? (Demo)</a><br/>Oder sucht euch die nächste Gelegenheit für ein Public Viewing. 
                        <a class="link" [href]="'https://decitto.de/?id=-MYqV8MgK-8Xcm21O7jA'" target="_blank" title="Beispiel zum Public Viewing" alt="Beispiel zum Public Viewing">Entscheiden, welche Bar es werden soll! (Demo)</a>
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem4">
                <div class="tablePicture flex" id="tablePicture4">
                    <img src="../../../assets/pictures/altersgrenze.svg" alt="Symbole für die Auswahl von ganz besonderen Ereignissen"  width="120px" height="120px">
                </div>
                <div class="tableText" id="tableText4">
                    <h5>Für die ganz besondere Auswahl</h5>
                    <p>
                        Auch die ganz besonderen Ereignisse müssen geplant und ausgewählt werden. 
                        Für die Ereignisse ab 18 gibt es auch ein paar Symbole zum schmunzeln.
                    </p>
                </div>
            </div>
            <div class="tableItem" id="tableItem22">
                <div class="tablePicture flex" id="tablePicture1">
                    <img src="../../../assets/ratingSymbols/PrideHeartChecked.svg" alt="Pride Heart Symbol" width="120px" height="120px">
                </div>
                <div class="tableText" id="tableText221">
                    <h5>Für ein gemeinsames miteinander</h5>
                    <p>
                        So bunt wie die Welt ist auch die Liebe. Lasst Farbe in eure gemeinsame Liste einziehen. Mit den zwei Bewertungssymbolen in Regenbogenoptik. 
                    </p>
                </div>
            </div>
        </div>
        </div>
        </div>
        <div 
        class="flex startbuttonArea"
        (click)="onStartbuttonClicked()">
        <img src="../../../assets/buttons/LosGehts.svg" alt="Starten" id="startbutton" width="300rem">
        </div>
        <div class="flex startbuttonArea" id="characteristics">
            <p><span style="font-size:14px">ohne Anmeldung & kostenfrei nutzbar</span><br>Mit dem Klick auf "Los geht's!" wird das Setzen von Cookies<br>
            für Dienste zur Analyse und Verbesserung dieser Website zugestimmt.
            </p>
        </div>
        <div class="other flex " style="padding-top: 30px;">
            <app-other></app-other>
        </div>
    </div>
</div>

<!--Startpage Advertisement-->
<div class="startpageAd flex">
    <div class="startpageAdContainer flex">
    <ng-adsense
        [display]="'inline-block'"
        [adFormat]="'vertical'"
        [fullWidthResponsive]="false"
        [width]="120"
        [height]="800"
    ></ng-adsense>
    </div>
</div>

<!--Startpage Dialog-->
<div class="transparentOverlay" *ngIf="newSessionShown"></div>
<div class="newSessionPage" id="newSession" *ngIf="newSessionShown">
    <div class="close" (click)="newSessionShown=false">
        <img src="../../../assets/buttons/close.svg" width="30px" class="closePic">
    </div>
    <p>Vergib ein Thema:</p>
    <div>
        <app-textfield
            [placeholder]="'Thema'"
            [label]="'Thema'"
            [id]="'topic'"
            (change)="onNewTopic($event)"
            (enter)="onNewTopic($event)"
            [fontsize]="'20px'"
            [maxlength]="25"
            [autoFocus]="true"
        ></app-textfield>
    </div>
    <p>Gib deinen Namen ein:</p>
    <div id="firstNameContainer">
        <app-textfield 
            [placeholder]="'Name'"
            [label]="'Name'"
            [id]="'firstName'"
            (change)="onNewName($event)"
            [fontsize]="'20px'"
            [maxlength]="16"
            (enter)="onAcceptClicked()"
        ></app-textfield>
    </div>
    <div class="flexLeft">
        <div id="symbolChange">
            <p>Soll es möglich sein andere Bewertungssymbole auszuwählen?</p>
        </div>
        <div class="checkbox">
            <mat-slide-toggle (change)="onSymbolCheckboxChange($event)"></mat-slide-toggle>
        </div>
    </div>
    <div class="flexLeft" *ngIf="symbolChoice">
        <div id="ageDescription">
            <p><span>Sind alle deine Mitentscheider volljährig und möchtest du eine erweiterte Auswahl mit nicht ganz ernst gemeinten Symbolen?</span>
                <span style="font-size:8px">Durch Aktivierung wird bestätigt, dass der Sessionlink nur Personen über 18 Jahren zur Verfügung gestellt wird.</span>
                <span style="font-size:8px">Diese Entscheidung kann nachträglich nicht verändert werden.</span>
            </p>
        </div>
        <div class="checkbox">
            <mat-slide-toggle (change)="onAgeConfirmationChange($event)"></mat-slide-toggle>
        </div>
    </div>
    <div class="flexLeft" *ngIf="symbolChoice">
        <div id="symbolChoiceText">
            <p>Auswahl des Bewertungssymbols:</p>
        </div>
        <div>
            <mat-form-field appearance="standard" id="symbolChoiceSelect">
                <mat-label>Symbol wählen</mat-label>
                <mat-select name="symbolChoice" (selectionChange)="onSymbolChoiceChange($event)">
                    <mat-option value=0>Sterne</mat-option>
                    <mat-option value=1>Ausrufezeichen</mat-option>
                    <mat-option value=10>Fußball</mat-option>
                    <mat-option value=2>Bier</mat-option>
                    <mat-option value=3>Wein</mat-option>
                    <mat-option value=4>Cocktail</mat-option>
                    <mat-option value=5>Strand</mat-option>
                    <mat-option value=6>Sonnenuntergang</mat-option>
                    <mat-option value=7>Bergsteigen</mat-option>
                    <mat-option value=11>Pride Flagge</mat-option>
                    <mat-option value=12>Pride Herz</mat-option>
                    <mat-option value=8 *ngIf="ageConfirmation">Mann</mat-option>
                    <mat-option value=9 *ngIf="ageConfirmation">Frau</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="checkbox" id="symbolPicture"></div>
    </div>
    <div 
        class="flex"
        (click)="onAcceptClicked()">
        <img src="../../../assets/buttons/Starten.svg" id="acceptbutton" width="170rem">
    </div>
    <div class="flex" id="sessionPageHints">
        <p>Jede Person in Besitz des Session Links kann auf die Session zugreifen.<br>Aus Sicherheitsgründen daher bitte keine sensiblen Informationen abspeichern.<br>
        Die Session ist nach Eröffnung 6 Monate nutzbar. <br>Nach 6 Monaten werden die Daten unwiderruflich gelöscht.
        </p>
    </div>
</div>

<!--Startpage Footer-->
<div class="footer">
    <div class="footerContainer">
        <a class="picture flex" id="facebookLogo" [href]="'https://www.facebook.com/Decitto-Entscheidet-gemeinsam-104027998473655'" target="_blank" title="Folge uns auf Facebook" alt="Folge uns auf Facebook">
          <img src="../../../assets/logos/Facebook_f_logo_(2019).svg" height="17px" alt="Folge uns auf Facebook">
        </a>
        <a class="picture flex" id="twitterLogo" [href]="'https://twitter.com/decitto'" target="_blank" title="Folge uns auf Twitter" alt="Folge uns auf Twitter">
            <img src="../../../assets/logos/twitter.svg" height="18px" alt="Folge uns auf Twitter">
        </a>
        <a class="text flex" [href]="'/about'" >Über uns</a>
        <a class="text flex" [href]="'/faq'" >FAQ</a>
        <a class="text flex" (click)="onKontaktClicked()">Kontakt</a>
        <a class="text flex" [href]="'/impressum'" rel="follow">Impressum & Datenschutz</a>
    </div>
  </div>