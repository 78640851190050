import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-communication-box',
  templateUrl: './communication-box.component.html',
  styleUrls: ['./communication-box.component.scss']
})
export class CommunicationBoxComponent implements OnInit {

  @Input()
  public id ="";

  @Input()
  public placeholder ="";

  @Input()
  public question ="";

  @Input()
  public width = 38;

  @Input()
  public height = 18; 
  
  @Input()
  public maxlength =100; 

  @Input()
  public initialContent = ""; 

  @Input()
  public textarea = false;

  @Input()
  public multipleTextfields = false;

  @Input()
  public singleChoice = false;

  @Input()
  public floatingLabels = false;

  @Input()
  public confirmQuery = false;

  @Input()
  public initialContentList: string[] = [""];
  
  @Input()
  public preventTopZIndex = false;

  @Output()
  public close = new EventEmitter<boolean>();

  @Output()
  public contentChange = new EventEmitter<string>();

  @Output()
  public contentListChanged = new EventEmitter<string[]>();

  @Output()
  public singleChoiceSelected = new EventEmitter<number>();

  public newContent = "";
  public newContentList: string[] = [""];
  public normalMode = true;
  private singleChoiceNumber = 999;

  @ViewChild("input", {static : false}) 
  set input(inputFocus: ElementRef<HTMLInputElement>){
    if (inputFocus){
      if (this.textarea){
        inputFocus.nativeElement.focus();
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
    if (!this.multipleTextfields){
      this.newContent = this.initialContent.slice();
    };
    this.normalMode = !(this.textarea || this.multipleTextfields || this.singleChoice || this.confirmQuery);
  }

  ngOnChanges(): void {
    if(this.textarea && this.height<30){
      this.height=30;
    }
    else if (this.multipleTextfields){
      this.height=18+2.5*this.initialContentList.length;
      this.newContentList = this.initialContentList.slice();
    }
    else if (this.singleChoice){
      this.height=20+this.initialContentList.length;
    }
  }

  onCloseClick(event?: any):void{
    this.close.emit(false);
  } 

  onNewContent(event: string):void{
    this.newContent = event;
  } 

  onAddClick(event?: any):void {
    if (this.multipleTextfields){
      this.contentListChanged.emit(this.newContentList);
    }
    else if (this.singleChoice){
      if (this.singleChoiceNumber != 999){
        this.singleChoiceSelected.emit(this.singleChoiceNumber);
      }
    }
    else {
      this.contentChange.emit(this.newContent);
    }
  }

  onEnter(event: string):void {
    this.initialContent = event;
    this.contentChange.emit(this.newContent);
  }

  onNewContentArray(event: string, i: number):void{
    this.newContentList[i] = event;
  } 

  onEnterArray(event: string, i: number):void {
    this.newContentList[i] = event;
  }

  onSingleChoiceChange(event: any, i: number):void {
    this.singleChoiceNumber = i;
  }
}
