<div class="fullScreenPage" id="impressum-page">
    <div class="close" (click)="onClickClose()">
        <img src="../../../assets/buttons/close.svg" width="30px" class="closePic">
    </div>
    <div class="fullScreenContent">
        <h1 style="text-align: justify;">Impressum</h1>
        <p style="text-align: justify;">Angaben gemäß § 5 TMG</p>
        <p style="text-align: justify;">Achim Röll<br>
        <a *ngIf="!adressShown" (click)="adressShown = true" class="link">Adresse anzeigen <br></a>
        <span *ngIf="adressShown">Ederstr. 6<br>
        64546 Mörfelden-Walldorf</span><br>
        Deutschland</p>
        <p style="text-align: justify;"><strong>Kontakt:</strong><br>
        Kontaktieren Sie uns per <a (click)="onMailClicked()" class="link">Mail</a>.<br>
        <a *ngIf="!callNumber" (click)="callNumber= true" class="link">Telefonnummer anzeigen <br></a>
        <span *ngIf="callNumber">Telefonnummer: 0175 6967540</span>
        </p>
        <p style="text-align: justify;"><strong>Haftungsausschluss </strong></p>
        <p style="text-align: justify;">Haftung für Inhalte:</p>
        <p style="text-align: justify;">Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir 
            jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. 
            Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu 
            forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen 
            bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden 
            von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
        <p style="text-align: justify;">Haftung für Links:</p>
        <p style="text-align: justify;">Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese 
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. 
            Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht 
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei 
            Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
        <p style="text-align: justify;"><strong>Urheberrecht</strong></p>
        <p style="text-align: justify;">Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die 
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des 
            jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf 
            dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Alle 
            verwendeten Fotografien stehen unter entsprechender Lizenz und stammen von den Medienplattformen <a href="http://www.pixabay.com" target="_blank">Pixabay©</a>. Die Icons stammen 
            von <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/de/" title="Flaticon">www.flaticon.com</a> und stehen unter entsprechender Lizenz. Sollten Sie trotzdem 
            auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte 
            umgehend entfernen. </p>
        <p style="text-align: justify;"><strong>Datenschutz</strong></p>
        <p style="text-align: justify;">Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene 
            Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne 
            Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.<br>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der 
        Daten vor dem Zugriff durch Dritte ist nicht möglich.<br>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und 
        Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten 
        Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
        <p style="text-align: justify;"><strong>Google Analytics</strong></p>
        <p style="text-align: justify;">Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Die Benutzung erfolgt auf der Grundlage 
            des Art. 6 Abs. 1 S. 1 lit. f. DSGVO. Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse 
            der Benutzung der Webseite durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung der Webseite wie Browser-Typ / -Version, 
            verwendetes Betriebssystem, Referrer-URL (die zuvor besuchte Seite), Hostname des zugreifenden Rechners (IP-Adresse) oder die Uhrzeit der Serveranfrage, werden 
            in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse 
            wird nicht mit anderen Daten von Google zusammengeführt. Wir haben zudem auf dieser Webseite Google Analytics um den Code „anonymizeIP“ erweitert. Dies garantiert 
            die Maskierung Ihrer IP-Adresse, sodass alle Daten anonym erhoben werden. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA 
            übertragen und dort gekürzt.<br>
        Im Auftrag des Betreibers dieser Website wird Google diese Information benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseiten-Aktivitäten
         zusammenzustellen und um weitere mit der Webseiten-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Sie können 
         die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall 
         möglicherweise nicht sämtliche Funktionen dieser Webseite vollumfänglich werden nutzen können.<br>
        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie 
        die Verarbeitung dieser Daten durch Google verhindern, indem sie das <a href="http://tools.google.com/dlpage/gaoptout?hl=de">verfügbare Browser-Plugin herunterladen 
            und installieren</a>.<br>
        Wir nutzen Google Analytics weiterhin dazu, Daten aus Double-Click-Cookies und auch AdWords zu statistischen Zwecken auszuwerten. Sollten Sie dies nicht wünschen, 
        können Sie dies über den <a href="http://www.google.com/settings/ads/onweb/?hl=de">Anzeigenvorgaben-Manager</a> deaktivieren.<br>
        Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der 
        <a href="https://support.google.com/analytics/answer/6004245?hl=de">Google Analytics-Hilfe</a>. </p>
        </div>
</div>