<div *ngIf="!mobileView">
<div class="ranking" 
    [innerHTML]="rankTitel">
</div>
<div class="ranking">
    <app-star-rating
        [usable]="false"
        [rating]="roundedRating"
        [symbolNumber]="symbolNumber" 
        [rateId]="rateId"        
    ></app-star-rating>
</div>
<div class="functionArea">
    <div class="function" title="Vorschlag auswählen" [class.filterGrey]="wonStatus==false" [class.filterGreen]="wonStatus==true" (click)="onWonStatus()">
        <img class="functionSymbol" id="wonStatus" src="../../../assets/buttons/hakchen.svg" width="18px" height="15px" >
    </div>
    <div class="function" title="Vorschlag nicht mehr möglich" [class.filterGrey]="impossibleStatus==false" [class.filterRed]="impossibleStatus==true"  (click)="onSetImpossible()">
        <img class="functionSymbol" id="setImpossible" src="../../../assets/buttons/notPossible.svg" width="12px" height="15px">
    </div>
    <div class="function filterGrey" title="Vorschlag löschen" (click)="showSugDelConfirmBox=true">
        <img class="functionSymbol" id="delete" src="../../../assets/buttons/loschen.svg" width="16px" height="15px">
    </div>
</div>
</div>
<div class="mobileView" *ngIf="mobileView">
    <div class="ranking mobileViewRanking">
        <app-star-rating
            [usable]="false"
            [rating]="roundedRating"
            [symbolNumber]="symbolNumber" 
            [rateId]="rateId"        
        ></app-star-rating>
    </div>
    <div class="functionAreaMobileView">
        <button mat-icon-button [matMenuTriggerFor]="menu"> <img src="../../../assets/buttons/tune.svg" alt="Weitere Optionen" title="Weitere Optionen" width="24px" style="margin-top: -4px;"></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item>
                <div class="flex" title="Als ausgewählt markieren" [class.filterGreyMobile]="wonStatus==false" [class.filterGreen]="wonStatus==true" (click)="onWonStatus()">
                    <span style="width: 20px; height: 20px;">
                        <img class="functionSymbol" id="wonStatus" src="../../../assets/buttons/hakchen.svg" width="18px" height="15px" style="padding-top: 3px;">
                    </span>
                    <span style="margin-left: 10px;">Als ausgewählt markieren</span>
                </div>
            </button>
            <button mat-menu-item>
                <div class="flex" title="Nicht mehr möglich" [class.filterGreyMobile]="impossibleStatus==false" [class.filterRed]="impossibleStatus==true"  (click)="onSetImpossible()">
                    <span style="width: 20px; height: 20px">
                    <img class="functionSymbol" id="setImpossible" src="../../../assets/buttons/notPossible.svg" width="12px" height="15px" style="padding-top: 2px;">
                    </span>
                    <span style="margin-left: 15px;">Nicht mehr möglich</span>
                </div>
            </button>
            <button mat-menu-item>
                <div class="filterGreyMobile flex" title="Vorschlag löschen" (click)="showSugDelConfirmBox=true">
                    <span style="width: 20px; height: 20px">
                    <img class="functionSymbol" id="delete" src="../../../assets/buttons/loschen.svg" width="16px" height="15px" style="padding-top: 1px; margin-left: -2px; margin-right: 2px;">
                    </span>
                    <span style="margin-left: 10px;">Vorschlag löschen</span>
                </div>
            </button>
        </mat-menu>
    </div>
    <div class="ranking mobileViewRanking" 
    [innerHTML]="rankTitel">
    </div>
</div>

<!--Communication Box for confirm suggestion deletion-->
<app-communication-box
    *ngIf="showSugDelConfirmBox"
    [id]="'sugDeletion'"
    [question]="'Vorschlag &ldquo;' + sugLabel.substr(0,20) + '&rdquo; endgültig löschen?'"
    (close)="showSugDelConfirmBox=$event"
    (contentChange)="onDelete()"
    [confirmQuery]="true">
</app-communication-box>

