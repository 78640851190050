<div class="container">
    <div class="star">
        <app-star-rating 
            [rateId]="userSugId" 
            [rating]="rating" 
            (ratingChange)="onChangeRating($event)"
            [usable]="inputUsable"
            [symbolNumber]="symbolNumber" >
        </app-star-rating>
    </div>
    <div class="text">
        <app-textfield 
            [placeholder]="'Kommentar'"
            [label]="'Kommentar'"
            [id]="'comment_'+userSugId"
            [scrollable]="true"
            [content]="userComment"
            (change)="onUserComment($event)"
            (enter)="onUserComment($event)"
        ></app-textfield>
    </div>
</div>