import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss']
})
export class OtherComponent implements OnInit {

  public currentURL = "";
  public mailString = "";
  public whatsappString = "";
  public facebookString = "";
  public telegramString = "";
  public threemaString = "";

  public showCopyLink = true;
  public showCopySuccess = false;

  constructor() { }

  ngOnInit(): void {
    this.currentURL = window.location.href;
    this.mailString = "mailto:?Subject=Lass uns gemeinsam entscheiden mit DecItTo!&body=Lass uns gemeinsam entscheiden mit DecItTo!: %0D%0A" + this.currentURL + " %0D%0A";
    this.whatsappString = "https://api.whatsapp.com/send?text=Lass uns gemeinsam entscheiden mit DecItTo!: " + this.currentURL;
    this.facebookString = "https://www.facebook.com/sharer.php?u="+ this.currentURL + "&t=Lass uns gemeinsam entscheiden mit DecItTo!";
    this.telegramString = "https://t.me/share/url?url="+ this.currentURL + "&text=Lass uns gemeinsam entscheiden mit DecItTo!";
    this.threemaString = "https://threema.id/compose?text=Lass uns gemeinsam entscheiden mit DecItTo!: " + this.currentURL;
  }

  public onCopyClick(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.currentURL;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showCopyLink = false;
    this.showCopySuccess = true;
  }
}
