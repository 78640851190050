<div class="toolbar" role="banner">
    <a href="./" title="Zurück zur Startseite" alt="Logo zur Startseite">
    <img id="logo"
      width="40"
      alt="Logo"
      src="../../../assets/pictures/favicon.svg"
    />
    </a>
    <h1>DecItTo! - Entscheidet gemeinsam!</h1>
      <div class="spacer"></div>
      <div class="logos" *ngIf="false">
      <a aria-label="English" target="_blank" rel="noopener" title="English">
        <img id="english-logo" width="30" height="20" data-name="Logo" src="../../../assets/buttons/Flag_of_the_United_States.svg"> 
      </a>
      <a aria-label="Deutsch" target="_blank" rel="noopener" title="Deutsch">
        <img id="deutsch-logo" width="30" height="20" data-name="Logo" src="../../../assets/buttons/Flag_of_Germany_(3-2_aspect_ratio).svg"> 
      </a>
      </div>
  </div>
