<div class="Input" *ngIf="!floatingLabels">
  <input 
    type="text" 
    [id]="id" 
    class="Input-text" 
    [style.font-size]="fontsize" 
    [maxLength]="maxlength" 
    [value]="content" 
    [placeholder]="placeholder" 
    (keyup)="onContentChange(input.value)" 
    (keyup.enter)="onEnter(input.value)"
    (change)="onChange($event)"
    (isTrusted)="preventDefault($event)"
    #input
  >
</div>

<div class="floatingInput" *ngIf="floatingLabels">
  <input 
    type="text" 
    [id]="id" 
    class="floatingInput-text" 
    [style.font-size]="fontsize" 
    [maxLength]="maxlength" 
    [value]="content" 
    [placeholder]="placeholder" 
    (keyup)="onContentChange(input.value)" 
    (keyup.enter)="onEnter(input.value)"
    (change)="onChange($event)"
    (isTrusted)="preventDefault($event)"
    #input
  >
  <label for="{{id}}" class="floatingInput-label" [innerHTML]="placeholder" ></label>
</div>