<div class="fullScreenPage" id="faq-page">
    <div class="close" (click)="onClickClose()">
        <img src="../../../assets/buttons/close.svg" width="30px" class="closePic">
    </div>
    <div class="fullScreenContent">
        <H1>Hilfe</H1>
        <p>Hier findest du die Funktionen von DecItTo! in einem Bild erklärt. Solltest du noch weitere Fragen haben, dann schaue in unseren <a class="link" (click)="toFaqChangeClicked()">FAQ</a> nach.</p>
        <p><span style="font-weight: bold;">Tipp: </span>Halte während du scrollst die Shift-Taste gedrückt, um von links nach rechts zu scrollen.</p>
        <div class="image">
            <img src="../../../assets/pictures/Start Erklärung v3.svg" width=100% height="100%">
        </div>
        <p>Mobile Ansicht (Smartphone Porträtmodus):</p>
        <div class="image" style="max-width: 65rem;">
            <img src="../../../assets/pictures/Start Erklärung mobil.svg" width=100% height="100%">
        </div>
    </div>
</div>