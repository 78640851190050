import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ReplaySubject } from 'rxjs';
import { Session } from '../_interface/session';
import { Suggestion } from '../_interface/suggestion';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  public sessionObject: ReplaySubject<any> = new ReplaySubject(1);
  public updateSessionObject: ReplaySubject<any> = new ReplaySubject(1);
  public sessionString: string = "";

  constructor(public db: AngularFireDatabase, private location: Location) { 
   }

  createNewSession(session: Session) {
      let dbResponse =  this.db.list('/session').push(session)
      this.sessionString = dbResponse.key as string;
      return dbResponse;
  };

  callSession(sessionString: string) {
    this.sessionString= sessionString;
    let session: Session;
    session={
      topic: "",
      nbuser: 0,
      nbsuggestions: 0,
      userNames: [],
      sugList: [],
      symbolChoice: false,
      ageConfirmation: false,
      symbolNumber: 0,
      timestamp: 0,
      emptyRatingCalculation: true,
      demoSession: false,
    };

    this.db.database.ref('/session/'+sessionString).on('value', (snapshot) =>{
      const data = snapshot.val();
      if (data != null){
        Object.entries(data).forEach(([key, value]) => {
          session[key] = value; 
        });
        this.sessionObject.next(session);
      }
      else{
        this.location.replaceState('/');
        window.alert("Diese Session konnte leider nicht gefunden werden! Bitte starte eine neue Session.");
        window.location.reload();
      }
    });
    this.sessionObject.next(session);
    return this.sessionObject;
    }
    /**
    this.db.list('/session/'+sessionString).valueChanges().subscribe(dbObject =>{
      //Kann nach Löschen der Sessions ohne Timestamp in der Datenbank entfernt werden. 
      if (dbObject.length == 4){ 
        session={
          topic: dbObject[2] as string,
          nbuser: dbObject[1] as number,
          nbsuggestions: dbObject[0] as number,
          userNames: dbObject[3] as string[],
          sugList: [],
          symbolChoice: false,
          ageConfirmation: false,
          symbolNumber: 0,
          timestamp: 0,
          emptyRatingCalculation: true,
          demoSession: false,
        };
      }
      else if (dbObject.length == 5) { 
        session ={
          topic: dbObject[3] as string,
          nbuser: dbObject[1] as number,
          nbsuggestions: dbObject[0] as number,
          userNames: dbObject[4] as string[],
          sugList: dbObject[2] as Suggestion[],
          symbolChoice: false,
          ageConfirmation: false,
          symbolNumber: 0,
          timestamp: 0,
          emptyRatingCalculation: true,
          demoSession: false,
        };
      }
      else if (dbObject.length == 8) { 
        session ={
          topic: dbObject[6] as string,
          nbuser: dbObject[2] as number,
          nbsuggestions: dbObject[1] as number,
          userNames: dbObject[7] as string[],
          sugList: [],
          symbolChoice: dbObject[3] as boolean,
          ageConfirmation: dbObject[0] as boolean,
          symbolNumber: dbObject[4] as number,
          timestamp: dbObject[5] as number,
          emptyRatingCalculation: true,
          demoSession: false,
        };
      }
      else if (dbObject.length == 9) { 
        session ={
          topic: dbObject[7] as string,
          nbuser: dbObject[2] as number,
          nbsuggestions: dbObject[1] as number,
          userNames: dbObject[8] as string[],
          sugList: dbObject[3] as Suggestion[],
          symbolChoice: dbObject[4] as boolean,
          ageConfirmation: dbObject[0] as boolean,
          symbolNumber: dbObject[5] as number,
          timestamp: dbObject[6] as number,
          emptyRatingCalculation: true,
          demoSession: false,
        };
      }
      else if (dbObject.length == 10) { 
        session ={
          topic: dbObject[8] as string,
          nbuser: dbObject[4] as number,
          nbsuggestions: dbObject[3] as number,
          userNames: dbObject[9] as string[],
          sugList: [],
          symbolChoice: dbObject[5] as boolean,
          ageConfirmation: dbObject[0] as boolean,
          symbolNumber: dbObject[6] as number,
          timestamp: dbObject[7] as number,
          emptyRatingCalculation: dbObject[2] as boolean,
          demoSession: dbObject[1] as boolean,
        };
      }
      else if (dbObject.length == 11) { 
        session ={
          topic: dbObject[9] as string,
          nbuser: dbObject[4] as number,
          nbsuggestions: dbObject[3] as number,
          userNames: dbObject[10] as string[],
          sugList: dbObject[5] as Suggestion[],
          symbolChoice: dbObject[6] as boolean,
          ageConfirmation: dbObject[0] as boolean,
          symbolNumber: dbObject[7] as number,
          timestamp: dbObject[8] as number,
          emptyRatingCalculation: dbObject[2] as boolean,
          demoSession: dbObject[1] as boolean,
        };
      }
      else if (dbObject.length == 0) { 
        this.location.replaceState('/');
        window.location.reload();
        alert("Diese Session konnte leider nicht gefunden werden! Starte eine neue Session.")
      }
      this.sessionObject.next(session);
    }, err => {console.log(err);}, () => {});
    return this.sessionObject;
  };
   */

  updateSession(session: Session) {
    this.db.list('/session/').set(this.sessionString, session);
  };

  updateSessionValue(sessionKey: String, value: any) {
    var updates = {};
    updates['' + sessionKey] = value;
    this.db.database.ref('/session/' + this.sessionString + '/').update(updates);
  };

  updateSuggestionValue(suggestion: Suggestion, suggestionKey: String, value: any) {
    var updates = {};
    updates['' + suggestionKey] = value;
    this.db.database.ref('/session/' + this.sessionString + '/sugList/').update(updates);
  };
}