import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  @Output()
  public faqClosed = new EventEmitter<boolean>();

  constructor(private metaService:Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.metaService.getTag
    this.metaService.updateTag( { name:'description',content:"FAQ zu DecItTo! - Vorschläge bewerten und gemeinsam Entscheidung treffen mit dem Online-Tool DecItTo!"}, "name='description'");
    this.titleService.setTitle("DecItTo! | FAQ");
  }

  onClickClose(): void{ 
    this.faqClosed.emit(false);
  }
}
