import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { SuggestionComponent } from './components/suggestion/suggestion.component';
import { TextfieldComponent } from './components/textfield/textfield.component';
import { TemplateHeaderComponent } from './_templates/template-header/template-header.component';
import { OtherComponent } from './components/other/other.component';
import { UserRatingComponent } from './components/user-rating/user-rating.component';
import { RankingComponent } from './components/ranking/ranking.component';
import { MainFeatureComponent } from './components/main-feature/main-feature.component';
import { TemplateFooterComponent } from './_templates/template-footer/template-footer.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { StartPageComponent } from './components/start-page/start-page.component';
import { SuggestionHeaderComponent } from './components/suggestion-header/suggestion-header.component';
import { FaqComponent } from './components/faq/faq.component';
import { NewSuggestionComponent } from './components/new-suggestion/new-suggestion.component';
import { CommunicationBoxComponent } from './components/communication-box/communication-box.component';
import { FeatureContainerComponent } from './feature-container/feature-container.component';
import { HelpPageComponent } from './components/help-page/help-page.component';

import { FirebaseService } from './_services/firebase.service';

import { AdsenseModule } from 'ng2-adsense';

//Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from '../environments/environment';

//Cockie Consent
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { AboutComponent } from './components/about/about.component';

//Material Design
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    AppComponent,
    StarRatingComponent,
    SuggestionComponent,
    TextfieldComponent,
    TemplateHeaderComponent,
    OtherComponent,
    UserRatingComponent,
    RankingComponent,
    MainFeatureComponent,
    TemplateFooterComponent,
    ImpressumComponent,
    StartPageComponent,
    SuggestionHeaderComponent,
    FaqComponent,
    NewSuggestionComponent,
    CommunicationBoxComponent,
    FeatureContainerComponent,
    HelpPageComponent,
    AboutComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'DecItTo'),
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    NgcCookieConsentModule.forRoot(environment.cookieConfig as NgcCookieConsentConfig),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-6725246468563409',
      adSlot: 9345967163,
    }),
    BrowserAnimationsModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatButtonModule,
  ],
  providers: [
    FirebaseService,
    Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
