import { Component, Input, OnInit, Output, EventEmitter, HostBinding, HostListener, ViewChild, ElementRef, AfterViewInit, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MobileViewInformation } from '../../_interface/mobile-view-information';

@Component({
  selector: 'app-suggestion-header',
  templateUrl: './suggestion-header.component.html',
  styleUrls: ['./suggestion-header.component.scss']
})
export class SuggestionHeaderComponent implements OnInit {

  @Input()
  public userNames: string[] = [""];

  @Input()
  public nbuser: number = 0;

  @Input()
  public topic = "";

  @Input()
  public symbolChoice = false;

  @Input()
  public ageConfirmation = false;

  @Input()
  public emptyRatingCalculation = true;

  @Input()
  public symbolNumber = 0;

  @Output()
  public newUser = new EventEmitter<string>();

  @Output()
  public topicChanged = new EventEmitter<string>();

  @Output()
  public userNameChanged = new EventEmitter<string[]>();

  @Output()
  public symbolNumberChanged = new EventEmitter<number>();

  @Output()
  public userDeletion = new EventEmitter<number>();

  @Output()
  public emptyRatingCalculationChanged = new EventEmitter<boolean>();
  
  @Output()
  public mobileViewChange = new EventEmitter<MobileViewInformation>();

  public suggestionWidth = "";
  public nameInputShown = false; 
  public newName = "";
  public settingBoxShown = false;
  public topicChangeShown = false;
  public userNameChangeShown = false;
  public userDeletionShown = false;
  public userSelectionShown = false;
  public firstMobileViewStart = true;
  public topicText = "";
  public userNamesSelection: string[] = [""];
  public nameChoice = 0;

  private symbolNumberChangedIndicator = false;
  private calculationChangedIndicator = false;
  private newSymbolNumber = 0;
  public symbolURL = "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e";

  public mobileViewPossible = true;
  public mobileViewInformation: MobileViewInformation;

  @HostBinding("attr.style")
  public get getSymbolURL(): any {
    this.calculateWidth();
    return this.sanitizer.bypassSecurityTrustStyle(`--symbolPicture: url(\"${this.symbolURL}\"); `+`--suggestionHeaderWidth: ${this.suggestionWidth}; `);
  }

  constructor(private sanitizer: DomSanitizer) {
    this.mobileViewInformation={
      mobileView: false,
      mobileUserShown: 0,
      screenHeight: 0,
      screenWidth: 0,
    }
    this.getScreenSize();
   }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.newSymbolNumber = this.symbolNumber;
    this.updateText();
    this.getScreenSize();
    this.calculateWidth();
    this.updateSymbol();
    if(this.firstMobileViewStart){
      this.userNamesSelection = this.userNames.slice();
      this.userNamesSelection.push("Benutzer hinzufügen");
    }
    if(this.mobileViewInformation.mobileView && this.firstMobileViewStart){ 
      this.userSelectionShown = true;
     }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.mobileViewInformation.screenHeight = window.innerHeight;
    this.mobileViewInformation.screenWidth = window.innerWidth;
    const mobile: boolean = window.matchMedia("screen and (max-width: 60em) and (orientation:portrait)").matches;
    if (mobile && !this.mobileViewInformation.mobileView && this.mobileViewPossible){
      this.mobileViewInformation.mobileView = true;
    }
    else if (this.mobileViewInformation.mobileView && !this.mobileViewPossible || !mobile && this.mobileViewInformation.mobileView){
      this.mobileViewInformation.mobileView = false;
    }
    this.mobileViewChange.emit(this.mobileViewInformation);
    this.updateText();
  }

  onMobileUserChoiceChange(event: any):void {
    this.mobileViewInformation.mobileUserShown = +event.value;
    this.mobileViewChange.emit(this.mobileViewInformation);
    this.updateText();
  }

  onFirstUserChoiceChange(event: any):void {
    this.firstMobileViewStart = false;
    const nbNames = this.userNamesSelection.length-1;
    if(event === nbNames){
      this.nameInputShown = true; 
    }
    else{
      this.nameChoice = event;
      this.mobileViewInformation.mobileUserShown = event;
      this.mobileViewChange.emit(this.mobileViewInformation);
      this.updateText();
    }
    this.userSelectionShown = false;
  }

  onMobileViewPossibilityChange(event: any):void {
    this.mobileViewPossible = !event.checked;
  } 

  onNewName(event: string):void{
    this.newName = event;
    this.nameInputShown = false;
    this.newUser.emit(this.newName);
    if(this.mobileViewInformation.mobileView){
      this.nameChoice = this.userNames.length-1;
      this.mobileViewInformation.mobileUserShown = this.userNames.length-1;
      this.mobileViewChange.emit(this.mobileViewInformation);
      this.updateText();
    } 
  }
  
  onSymbolChoiceChange(event: any):void {
    this.newSymbolNumber = +event.value;
    this.symbolNumberChangedIndicator = true; 
    this.updateSymbol();
  }

  private updateSymbol():void{
    switch (+this.newSymbolNumber){
      case 0:
        this.symbolURL = "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e";
        break;
      case 1:
        this.symbolURL = "../../../assets/ratingSymbols/ausrufezeichenChecked.svg";
        break; 
      case 2:
        this.symbolURL = "../../../assets/ratingSymbols/bierChecked.svg";
        break;
      case 3:
        this.symbolURL = "../../../assets/ratingSymbols/weinChecked.svg";
        break;
      case 4:
        this.symbolURL = "../../../assets/ratingSymbols/cocktailChecked.svg";
        break;
      case 5:
        this.symbolURL = "../../../assets/ratingSymbols/sunbedChecked.svg";
        break;
      case 6:
        this.symbolURL = "../../../assets/ratingSymbols/sunsetChecked.svg";
        break;
      case 7:
        this.symbolURL = "../../../assets/ratingSymbols/climbingChecked.svg";
        break;
      case 8: 
        this.symbolURL = "../../../assets/ratingSymbols/adultMaleStandard.png";
        break;
      case 9: 
        this.symbolURL = "../../../assets/ratingSymbols/adultFemaleChecked.svg";
        break;
      case 10: 
        this.symbolURL = "../../../assets/ratingSymbols/footballChecked.svg";
        break;
      case 11: 
        this.symbolURL = "../../../assets/ratingSymbols/LGBTQ_Checked.svg";
        break;
      case 12: 
        this.symbolURL = "../../../assets/ratingSymbols/PrideHeartChecked.svg";
        break;
      default:
        break;
    }
  }

  onConfirmClicked():void{
    this.settingBoxShown = false;
    if (this.symbolNumberChangedIndicator){
      this.symbolNumberChanged.emit(this.newSymbolNumber);
      this.symbolNumberChangedIndicator=false;
      this.symbolNumber = this.newSymbolNumber;
    }
    if (this.calculationChangedIndicator){
      this.emptyRatingCalculationChanged.emit(this.emptyRatingCalculation);
      this.calculationChangedIndicator=false;
    }
    this.getScreenSize();
  }

  onSettingMenuClosed():void{
    this.settingBoxShown=false;
    if (this.symbolNumberChangedIndicator){
      this.symbolNumberChangedIndicator=false;
      this.newSymbolNumber = this.symbolNumber;
      this.updateSymbol();
    }
    if (this.calculationChangedIndicator){
      this.calculationChangedIndicator=false;
      this.emptyRatingCalculation=!this.emptyRatingCalculation;
    }
  }

  onChangeNames():void{
    this.settingBoxShown = false;
    this.userNameChangeShown = true;
  }

  onDeleteUser():void{
    this.settingBoxShown = false;
    this.userDeletionShown = true;
  }

  onChangeTopic():void{
    this.settingBoxShown = false;
    this.topicChangeShown = true;
  }

  onSymbolCheckboxChange(event: any):void {
    this.symbolChoice =  event.checked;
  }

  onCalculationCheckboxChange(event: any):void {
    this.emptyRatingCalculation = event.checked;
    this.calculationChangedIndicator = true;
  }

  onChangedTopic(event: string):void{
    this.topicChangeShown = false;
    this.topic = event;
    this.topicChanged.emit(event);
  }

  onChangedUserNames(event: string[]):void{
    this.userNameChangeShown = false;
    this.userNames = event;
    this.userNameChanged.emit(event);
  }

  onDeletedUser(event: number):void{
    this.userDeletionShown = false;
    this.userDeletion.emit(event);
  }

  private calculateWidth(): void{
    if (!this.mobileViewInformation.mobileView){
      this.suggestionWidth = (50 + 13.5*this.nbuser) + "rem";
    }
    else if (this.mobileViewInformation.mobileView){
      this.suggestionWidth = "28rem";
    }
  }

  private updateText(): void{
    if(this.mobileViewInformation.mobileView){
      if(this.topic.length > 18){
        this.topicText = this.topic.substring(0, 18) + "...";
      }
      else {
        this.topicText = this.topic;
      }
    }
    else {
      this.topicText = "Entscheidung über: " + this.topic;
    }
  }
}
