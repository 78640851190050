import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {

  @Input()
  public rating = 0;

  @Input()
  public usable = true;

  @Input()
  public rateId = "rating";

  @Input()
  public symbolNumber = 0;

  public rateIdNumbers: String[] = [""];
  public className = "rating";

  @Output()
  public ratingChange = new EventEmitter<number>();  

  //URL for CSS images
  private imageURLstandard = "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e";
  private imageURLChecked = "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e";
  private imageURLHover = "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e";

  @HostBinding("attr.style")
  public get getImageURLstandard(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--imageUrlStandard: url(\"${this.imageURLstandard}\"); `+`--imageUrlChecked: url(\"${this.imageURLChecked}\"); `+`--imageUrlHover: url(\"${this.imageURLHover}\")`);
  }

  constructor(private sanitizer: DomSanitizer) {     
  }

  ngOnInit(): void {
    for (let i = 0; i<5; i++){
      this.rateIdNumbers[i]= this.rateId + "_" + (i+1);
    }

    switch (this.symbolNumber){
      case 0: 
        break;
      case 1:
        this.imageURLstandard = "../../../assets/ratingSymbols/ausrufezeichenStandard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/ausrufezeichenChecked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/ausrufezeichenChecked.svg";
        break; 
      case 2:
        this.imageURLstandard = "../../../assets/ratingSymbols/bierStandard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/bierChecked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/bierHover.svg";
        break;
      case 3:
        this.imageURLstandard = "../../../assets/ratingSymbols/weinStandard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/weinChecked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/weinHover.svg";
        break;
      case 4:
        this.imageURLstandard = "../../../assets/ratingSymbols/cocktailStandard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/cocktailChecked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/cocktailChecked.svg";
        break;
      case 5:
        this.imageURLstandard = "../../../assets/ratingSymbols/sunbedStandard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/sunbedChecked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/sunbedChecked.svg";
        break;
      case 6:
        this.imageURLstandard = "../../../assets/ratingSymbols/sunsetStandard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/sunsetChecked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/sunsetChecked.svg";
        break;
      case 7:
        this.imageURLstandard = "../../../assets/ratingSymbols/climbingStandard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/climbingChecked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/climbingChecked.svg";
        break;
      case 8: 
        this.imageURLstandard = "../../../assets/ratingSymbols/adultMaleStandard.png";
        this.imageURLChecked = "../../../assets/ratingSymbols/adultMaleChecked.png";
        this.imageURLHover = "../../../assets/ratingSymbols/adultMaleChecked.png";
        break;
      case 9: 
        this.imageURLstandard = "../../../assets/ratingSymbols/adultFemaleStandard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/adultFemaleChecked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/adultFemaleChecked.svg";
        break;
      case 10: 
        this.imageURLstandard = "../../../assets/ratingSymbols/footballStandard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/footballChecked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/footballChecked.svg";
        break;
      case 11: 
        this.imageURLstandard = "../../../assets/ratingSymbols/LGBTQ_Standard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/LGBTQ_Checked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/LGBTQ_Checked.svg";
        break;
      case 12: 
        this.imageURLstandard = "../../../assets/ratingSymbols/PrideHeartStandard.svg";
        this.imageURLChecked = "../../../assets/ratingSymbols/PrideHeartChecked.svg";
        this.imageURLHover = "../../../assets/ratingSymbols/PrideHeartChecked.svg";
        break;
      default:
        break;
    }

    if(this.usable){
      this.className="rating";
    }
    else{
      this.className="display";
    }
  }

  public onChangeRating(rate: number): void{
    this.rating=rate;
    this.ratingChange.emit(rate);
  }
}
