import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Session } from 'src/app/_interface/session';
import { Suggestion } from '../../_interface/suggestion';
import { NewSessionInformation } from '../../_interface/new-session-information';
import { MobileViewInformation } from '../../_interface/mobile-view-information';
import { FirebaseService } from '../../_services/firebase.service';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-main-feature',
  templateUrl: './main-feature.component.html',
  styleUrls: ['./main-feature.component.scss'],
})
export class MainFeatureComponent implements OnInit {

  public session: Session;
  public overlayShown = false;
  public descriptionPageShown = false;
  public otherShown = false;
  public showCopyLink = true;
  public locationString = "";
  private firstLoad = true; 
  public mobileViewInformation: MobileViewInformation;
  private firstLoadObject = true;
  public loadingPageShown = true;
  public longLoadingTime = false;

  @Output()
  public mobileViewEvent = new EventEmitter<boolean>();

  @Input()
  public newSessionInformation: NewSessionInformation = {topic: "", name: "", symbolChoice: false, ageConfirmation: false, symbolNumber: 0};

  @Input()
  public sessionString = "";

  constructor(private firebase: FirebaseService, private location: Location, private metaService:Meta, private titleService: Title) { 
    this.mobileViewInformation ={
      mobileView: false,
      mobileUserShown: 0,
      screenHeight: 0,
      screenWidth: 0,
    };
    this.session ={
      topic: "",
      nbuser: 0,
      nbsuggestions: 0,
      userNames: [],
      sugList: [],
      symbolChoice: false,
      ageConfirmation: false,
      symbolNumber: 0,
      timestamp: 0,
      emptyRatingCalculation: true,
      demoSession: false,   
    };
  }

  ngOnInit(): void {
    setTimeout(() => { this.longLoadingTime = true; }, 6000);
    if (this.sessionString == ""){
      this.session = {
        topic: this.newSessionInformation.topic,
        nbuser: 1,
        nbsuggestions: 0,
        userNames: [this.newSessionInformation.name],
        sugList: [],
        symbolChoice: this.newSessionInformation.symbolChoice,
        ageConfirmation: this.newSessionInformation.ageConfirmation,
        symbolNumber: this.newSessionInformation.symbolNumber,
        timestamp: Date.now(),
        emptyRatingCalculation: true,
        demoSession: false,
      };
      this.firebase.createNewSession(this.session).then(res =>{
        this.sessionString = res.key as string;
        this.location.replaceState('?id=' + this.sessionString);
        this.firebase.callSession(this.sessionString).subscribe( session =>{
          this.session = session;
          this.locationString = window.location.href;
          if (this.firstLoad){
            this.overlayShown = true;
            this.descriptionPageShown = true;
            this.otherShown = true;
          }
          this.firstLoad = false;
          this.updateMetaInformation();
          if (!this.firstLoadObject){
            this.loadingPageShown = false;
          }
          else {
            this.firstLoadObject = false;
          }
        });
      }).catch(error => {
        console.log(error);});
    }
    else { 
      this.firebase.callSession(this.sessionString).subscribe( session =>{
        this.session = session;
        this.locationString = window.location.href;
        this.otherShown = true;
        this.updateMetaInformation();
        if (!this.firstLoadObject){
          this.loadingPageShown = false;
        }
        else {
          this.firstLoadObject = false;
        }
      });
    }
  }

  ngOnChanges(): void {
  }


  onNewSuggestion(event: string): void {
    this.overlayShown=true;
    this.session.sugList.push({ 
      id: Math.random()*500000,
      label: event,
      nbuser: this.session.nbuser,
      position: this.session.sugList.length + 1,
      overallrate: 0,
      wonStatus: false,
      userRatings: new Array(this.session.nbuser).fill(0),
      trackID:Math.random()*500000,
      positionChanged: 0,
      userComments: new Array(this.session.nbuser).fill(""),
      description: "",
      link: "",
      impossibleStatus: false,
      symbolNumber: this.session.symbolNumber
      });
    this.session.nbsuggestions++;
    this.updatePosition();
    this.updateSuggestions();
    this.updateSession();
    this.overlayShown=false;
  }

  onSuggestionChange(event: Suggestion, i: number): void {
    this.overlayShown=true;
    this.session.sugList[i] = event;
    this.calculateOverallrate(i);
    this.updatePosition();
    this.updateSuggestions();
    this.updateSessionValue("sugList", this.session.sugList);
    this.overlayShown=false;
  }

  onMobileViewChanged(event: MobileViewInformation): void {
    this.mobileViewInformation = event;
    this.mobileViewEvent.emit(event.mobileView);
  }

  public onNewUser(event: string): void {
    this.overlayShown=true;
    this.session.userNames.push(event);
    let newNbUser = this.session.userNames.length
    this.session.nbuser = newNbUser;
    for (let i=0; i<this.session.sugList.length; i++){
      this.session.sugList[i].nbuser = newNbUser;
      this.session.sugList[i].userRatings.push(0);
      this.session.sugList[i].userComments.push("");
      this.session.sugList[i].positionChanged=0;
    }
    this.calculateOverallrate();
    this.updateSuggestions();
    this.updateSession();
    this.overlayShown=false;
  }

  public trackItem (index: number, item: Suggestion) {
    return item.trackID;
  }

  public onDelete(event: Suggestion, i: number): void {
    if (i>-1){
      this.session.sugList.splice(i,1);
      this.session.nbsuggestions--;
    }
    this.updatePosition();
    for (let nbr=0; nbr<this.session.sugList.length; nbr++){
      if (nbr>=i){
        this.session.sugList[nbr].positionChanged=2;
      }
    }
    this.updateSuggestions();
    this.updateSession();
  }

  public onTopicChanged(event: string): void {
    this.session.topic = event;
    this.updateSessionValue("topic", event);
  }

  public onUserNameChanged(event: string[]): void {
    this.session.userNames = event;
    this.updateSessionValue("userNames", event);
  }

  public onSymbolNumberChanged(event: number): void {
    this.session.symbolChoice = true;
    this.session.symbolNumber = event;
    this.session.sugList.forEach(sug => {
      sug.symbolNumber = event;
    });
    this.updateSuggestions();
    this.updateSession();
  }

  public onUserDeleted(event: number): void {
    this.session.nbuser--;
    this.session.userNames.splice(event, 1);
    this.session.sugList.forEach(sug => {
        sug.nbuser--;
        sug.userRatings.splice(event, 1);
        sug.userComments.splice(event, 1);
    });
    this.calculateOverallrate();
    this.updatePosition();
    this.updateSuggestions();
    this.updateSession();
  }

  public onEmptyRatingCalculationChanged(event: boolean): void {
    this.session.emptyRatingCalculation = event;
    this.calculateOverallrate();
    this.updatePosition();
    this.updateSuggestions();
    this.updateSessionValue("emptyRatingCalculation", event);
  }

  private updateSuggestions(): void {
    for (let i=0; i<this.session.sugList.length; i++){
      this.session.sugList[i].trackID++;
    }
  }

  private updateSession(): void {
    if(!this.session.demoSession){
      this.firebase.updateSession(this.session);
    }
  }

  private updateSessionValue(key: String, value: any): void {
    if(!this.session.demoSession){
      this.firebase.updateSessionValue(key, value);
    }
  }

  private updatePosition():void {
    let newSuggestionList: Suggestion[];
    let oldPositionList: number[][] = [];
    this.session.sugList.forEach(sug => {
      oldPositionList.push([sug.id, sug.position]);
    });
    let lastPosition = 1;
    newSuggestionList = this.session.sugList.slice();
    newSuggestionList = newSuggestionList.sort((a,b) => b.overallrate - a.overallrate);
    newSuggestionList.forEach(sug => {
      if (sug.wonStatus){
        sug.position = lastPosition;
        lastPosition++;
      }
    });
    newSuggestionList.forEach(sug => {
      if (!sug.impossibleStatus && !sug.wonStatus){
        sug.position = lastPosition;
        lastPosition++;
      }
    });
    newSuggestionList.forEach(sug => {
      if (sug.impossibleStatus){
        sug.position = lastPosition;
        lastPosition++;
      }
    });
    newSuggestionList.forEach(sug => {
      let oldPosition = oldPositionList.find(oldSug => oldSug[0]==sug.id)![1];
      if(sug.position > oldPosition && !sug.impossibleStatus){
        sug.positionChanged = 1;
      }
      else if(sug.position < oldPosition && !sug.impossibleStatus){
        sug.positionChanged = 2;
      }
      else{
        sug.positionChanged = 0;
      }
    });
    this.session.sugList = newSuggestionList.sort((a,b) => a.position - b.position);
  }

  private calculateOverallrate(nb?: number): void {
    let from: number = 0;
    let to: number = 0;
    if (nb !== undefined){
      from = nb;
      to = nb + 1;
    }
    else {
      from = 0;
      to = this.session.sugList.length;
    }
    if (this.session.emptyRatingCalculation){
      for (from; from<to; from++){
        let suggestion = this.session.sugList[from];
        let arraysum = 0;
        suggestion.userRatings.forEach(element => {
          arraysum = element + arraysum;
        });
        suggestion.overallrate = arraysum / suggestion.nbuser;
      }
    }
    else if (!this.session.emptyRatingCalculation){    
      for (from; from<to; from++){
        let suggestion = this.session.sugList[from];
        let arraysum = 0;
        let nonEmptyRatings = 0;
        suggestion.userRatings.forEach(element => {
          if (element>0){
            nonEmptyRatings++;
            arraysum = element + arraysum;
          }
        });
        if (nonEmptyRatings>0){
        suggestion.overallrate = arraysum / nonEmptyRatings;
        }
        else{
          suggestion.overallrate = 0;
        }
      }
    }
  }

  public onCopyClick(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.locationString;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showCopyLink = false;
  }

  public reloadWindow(): void {
    window.location.reload();
  }

  private updateMetaInformation(): void {
    this.metaService.updateTag( { name:'description',content:"Entscheidung über: " + this.session.topic.substr(0,20) + "- Vorschläge bewerten und gemeinsam Entscheidung treffen mit dem Online-Tool DecItTo!"}, "name='description'");
    this.titleService.setTitle("DecItTo! | " + this.session.topic);
  }
}
