import { Component, OnInit, Input } from '@angular/core';
import { NewSessionInformation } from '../_interface/new-session-information';

@Component({
  selector: 'app-feature-container',
  templateUrl: './feature-container.component.html',
  styleUrls: ['./feature-container.component.scss']
})
export class FeatureContainerComponent implements OnInit {

  public mobileView = false; 

  @Input()
  public newSessionInformation: NewSessionInformation = {topic: "", name: "", symbolChoice: false, ageConfirmation: false, symbolNumber: 0};

  @Input()
  public sessionString ="";

  constructor() { }

  ngOnInit(): void {
  }
}
