import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-textfield',
  templateUrl: './textfield.component.html',
  styleUrls: ['./textfield.component.scss']
})
export class TextfieldComponent implements OnInit {

  @Input()
  public label = "";

  @Input()
  public id = "";

  @Input()
  public placeholder = "";

  @Input()
  public fontsize = "13px";

  @Input()
  public scrollable = false;

  @Input()
  public maxlength = 110;

  @Input()
  public readonly = false;

  @Input()
  public content = "";

  @Input()
  public autoFocus = false;

  @Input()
  public floatingLabels = false;

  @Output()
  public contentChange = new EventEmitter<string>();

  @Output()
  public change = new EventEmitter<string>();

  @Output()
  public enter = new EventEmitter<string>();

  @ViewChild("input", {static : false}) 
  set input(inputFocus: ElementRef<HTMLInputElement>){
    if (inputFocus){
      if (this.autoFocus){
        inputFocus.nativeElement.focus();
      }
      if (this.readonly){
        inputFocus.nativeElement.readOnly = this.readonly;
      }
    }
  }

  constructor() { 
  }

  ngOnInit(): void {
  }

  onContentChange(content: string): void{
    this.content = content;
    this.contentChange.emit(content);
  }

  onChange(event: any): void{
    this.preventDefault(event);
    this.content = event.target.value;
    this.change.emit(event.target.value);
  }

  onEnter(content: string): void{
    this.content = content;
    this.enter.emit(content);
  }

  preventDefault(event: Event): void{
    event.preventDefault();
    event.stopImmediatePropagation();
  }
}
