import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  @Output()
  public aboutClosed = new EventEmitter<boolean>();

  constructor(private metaService:Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.metaService.updateTag( { name:'description',content:"Über die Erfinder von DecItTo! - Vorschläge bewerten und gemeinsam Entscheidung treffen mit dem Online-Tool DecItTo!"}, "name='description'");
    this.titleService.setTitle("DecItTo! | Über uns");
  }

  onClickClose(): void{ 
    this.aboutClosed.emit(false);
  }
}
